import { FC, useEffect, useRef, useState } from 'react';
import {
  BoxProps,
  Button,
  Flex,
  Input,
  InputProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { CalendarValues } from '@uselessdev/datepicker';
import { format, isValid } from 'date-fns';
import { DatePicker, DatePickerProps } from './DatePicker';

interface DatePickerRangeInputProps {
  onChange: (dates: CalendarValues) => void;
  containerProps?: BoxProps;
  datePickerProps?: Partial<DatePickerProps>;
  inputProps?: InputProps;
  defaultValue?: CalendarValues;
  dateFormat?: string;
  dateMatch?: (value: string) => boolean;
  singleDateSelection?: boolean;
  reset?: boolean;
}

export const DatePickerRangeInput: FC<DatePickerRangeInputProps> = ({
  onChange,
  reset = false,
  defaultValue,
  containerProps,
  datePickerProps,
  inputProps,
  dateFormat = 'MM/dd/yyyy',
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [dates, setDates] = useState<CalendarValues>({
    start: null,
    end: null,
  });
  const [startValue, setStartValue] = useState('');
  const [endValue, setEndValue] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const calendarRef = useRef(null);

  const handleUpdateValues = (update: CalendarValues) => {
    setDates(update);
    const { start, end } = update;
    setStartValue(() => (isValid(start) ? format(start, dateFormat) : ''));
    setEndValue(() => (isValid(end) ? format(end, dateFormat) : ''));
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  useEffect(() => {
    if (!dates || !hasLoaded) return;
    onChange(dates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, hasLoaded]);

  // handle load default
  useEffect(() => {
    if (!defaultValue || hasLoaded) return;
    setHasLoaded(true);
    handleUpdateValues(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // handleReset
  useEffect(() => {
    if (!reset) return;
    handleUpdateValues({ start: null, end: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <Popover
      placement="auto"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      isLazy
    >
      <PopoverTrigger>
        <Flex
          cursor="pointer"
          onClick={onOpen}
          ref={initialRef}
          {...containerProps}
        >
          <Input
            placeholder="date"
            value={`${startValue}${endValue ? ` - ${endValue}` : ''}`}
            cursor="pointer"
            readOnly
            {...inputProps}
          />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        p={0}
        w="min-content"
        border="none"
        outline="none"
        shadow="none"
        _focus={{ boxShadow: 'none' }}
        ref={calendarRef}
      >
        <PopoverBody
          p={0}
          borderColor="main.medium"
          borderWidth="1px"
          shadow="sm"
          borderRadius="md"
        >
          <DatePicker
            value={{ start: dates?.start, end: dates?.end }}
            onSelectDate={handleUpdateValues}
            singleDateSelection={false}
            allowSelectSameDay
            {...datePickerProps}
          />
          <Flex width="100%" px={4} mb={3}>
            <Button onClick={onClose} width="100%" variant="transparent">
              {'Close'}
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
