import { _LOCATIONS_PARAMS, _LOCATIONS_VARIABLES } from './location';

export const RETAILER_LOCATIONS_FOR_RETAILER_QUERY = `
  query RETAILER_LOCATIONS_FOR_RETAILER_QUERY(${_LOCATIONS_PARAMS}) {
    locations(${_LOCATIONS_VARIABLES}) {
      uuid
      name
      billingName
      retailer {
        name
        uuid
        logoImage
      }
      myPermissions
    }
  }
`;

export const RETAILER_LOCATIONS_FOR_RETAILER_REQUEST_FORM_QUERY = `
  query RETAILER_LOCATIONS_FOR_RETAILER_REQUEST_FORM_QUERY(${_LOCATIONS_PARAMS}) {
    locations(${_LOCATIONS_VARIABLES}) {
      uuid
      name
      billingName
      timezone
      specialRequestsAvailable
      status
      retailer {
        name
        uuid
        logoImage
      }
      compliance {
        name
        shiftStartDelta
        shiftGapDelta
        maxShiftsPerDay
        maxHoursPerDay
        shiftMaxLength
        shiftMinLength
        preFlexViewDisclaimers {
          name
        }
        preFlexClaimDisclaimers {
          name
        }
        requiredWorkerPaperwork {
          name
        }
        breakRules {
          shiftMinutesToRequireBreak
          breakLength
          paid
          requiresTimestamps
        }
      }
      myPermissions
    }
  }
`;
