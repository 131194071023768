import { organization } from 'types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface OrganizationStore {
  activeOrganization: organization;
  updateActiveOrganization: (update: organization) => void;
}

const defaultState = {
  activeOrganization: {},
};

const OrganizationStore = (set): OrganizationStore => ({
  ...defaultState,
  updateActiveOrganization: organization =>
    set(prev => ({
      activeOrganization: {
        ...prev?.activeOrganization,
        ...organization,
      },
    })),
});

export const useOrganizationStore = create(devtools(OrganizationStore));
