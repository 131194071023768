import { NextPage } from 'next';
import Head from 'next/head';
import { Box, Flex, Image } from '@chakra-ui/react';
import { Logo } from 'ui';
import { LoginForm } from 'components/auth/LoginForm';

const OpsLogin: NextPage = () => {
  return (
    <>
      <Head>
        <title>{'Reflex | Login to Oz'}</title>
      </Head>
      <Flex
        width="100%"
        height="100vh"
        backgroundColor="main.white"
        flexGrow={1}
        position="relative"
        overflow="hidden"
      >
        <Flex maxWidth="xl" mx="auto" position="relative" zIndex={2}>
          <Flex mt={12} maxWidth="md" flexDirection="column">
            <Flex width="100%" justifyContent="space-between">
              <Logo color="main.navy" alignSelf="flex-start" />
              <Flex pt={2}>
                <Image
                  src="/images/logo.svg"
                  h={12}
                  w={12}
                  alt="Oz Logo"
                  mr={2}
                  alignSelf="flex-end"
                />
              </Flex>
            </Flex>
            <LoginForm />
          </Flex>
        </Flex>
        <Box
          position="absolute"
          width="100%"
          height="100%"
          bottom={-5}
          left="50%"
          transform="translateX(-50%) scaleX(-1) rotate(180deg)"
          margin="0 auto"
          backgroundImage="/images/wavy.svg"
          filter="invert(27%) sepia(27%) saturate(305%) hue-rotate(162deg) brightness(91%) contrast(96%)"
          backgroundPosition="top center"
          backgroundRepeat="no-repeat"
          zIndex={1}
          aria-hidden="true"
          className="page-decoration"
        />
      </Flex>
    </>
  );
};

export default OpsLogin;
