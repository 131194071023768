import {
  addDays,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isSameDay,
  startOfMonth,
} from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum BudgetPeriodWizardView {
  DEFAULT = 'default',
  CONFIRMATION = 'confirmation',
  SUCCESS = 'success',
}

export type FormBudgetPeriod = {
  uuid: string;
  label: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  error: string | undefined;
};

interface BudgetPeriodWizardStore {
  activeView: BudgetPeriodWizardView;
  updateActiveView: (view: BudgetPeriodWizardView) => void;
  budgetPeriods: FormBudgetPeriod[];
  updateBudgetPeriod: (uuid: string, update: Partial<FormBudgetPeriod>) => void;
  addBudgetPeriod: () => void;
  removeBudgetPeriod: (uuid: string) => void;
  resetState: () => void;
  checkForErrors: () => void;
}

const generateFormBudgetPeriod = (
  lastExistingPeriod?: FormBudgetPeriod,
): FormBudgetPeriod => {
  const startDate = lastExistingPeriod?.endDate
    ? addDays(lastExistingPeriod.endDate, 1)
    : startOfMonth(new Date());
  const endDate = endOfMonth(startDate);
  const label = `${format(startDate, 'MMM yy')}'`;
  return {
    uuid: uuidv4(),
    label,
    startDate,
    endDate,
    error: undefined,
  };
};

const defaultState = {
  activeView: BudgetPeriodWizardView.DEFAULT,
  budgetPeriods: [generateFormBudgetPeriod()],
};

const BudgetPeriodWizardStore = (set, get): BudgetPeriodWizardStore => ({
  ...defaultState,
  updateActiveView: view => set({ activeView: view }),
  updateBudgetPeriod: (uuid, update) => {
    set(prev => ({
      budgetPeriods: prev.budgetPeriods.map(bp =>
        bp.uuid === uuid ? { ...bp, ...update } : bp,
      ),
    }));
  },
  addBudgetPeriod: () => {
    set(prev => ({
      budgetPeriods: [
        ...prev.budgetPeriods,
        generateFormBudgetPeriod(
          prev.budgetPeriods[prev.budgetPeriods.length - 1],
        ),
      ],
    }));
  },
  removeBudgetPeriod: uuid => {
    set(prev => ({
      budgetPeriods: prev.budgetPeriods.filter(bp => bp.uuid !== uuid),
    }));
  },
  resetState: () => set(defaultState),
  checkForErrors: () => {
    const { budgetPeriods, updateBudgetPeriod } = get();
    budgetPeriods.forEach((bp, index) => {
      if (!bp.startDate || !bp.endDate) {
        updateBudgetPeriod(bp.uuid, {
          error: 'Start and end date are required',
        });
        return;
      }
      if (isBefore(bp.endDate, bp.startDate)) {
        updateBudgetPeriod(bp.uuid, {
          error: 'End date must be after start date of this period',
        });
        return;
      }
      if (index === 0) {
        updateBudgetPeriod(bp.uuid, { error: undefined });
        return;
      }
      const endDatePrevPeriod = budgetPeriods[index - 1]?.endDate;
      if (endDatePrevPeriod && isBefore(bp.startDate, endDatePrevPeriod)) {
        updateBudgetPeriod(bp.uuid, {
          error: 'Start date must be after end date of previous period',
        });
        return;
      }
      if (endDatePrevPeriod && isSameDay(bp.startDate, endDatePrevPeriod)) {
        updateBudgetPeriod(bp.uuid, {
          error: "Start date can't be same day as end date of previous period",
        });
        return;
      }
      if (index === budgetPeriods.length - 1) {
        updateBudgetPeriod(bp.uuid, { error: undefined });
        return;
      }
      const startDateNextPeriod = budgetPeriods[index + 1]?.startDate;
      if (startDateNextPeriod && isAfter(bp.endDate, startDateNextPeriod)) {
        updateBudgetPeriod(bp.uuid, {
          error: 'End date must be before start date of next period',
        });
        return;
      }
      if (startDateNextPeriod && isSameDay(bp.endDate, startDateNextPeriod)) {
        updateBudgetPeriod(bp.uuid, {
          error: "End date can't be same day as start date of next period",
        });
        return;
      }
      updateBudgetPeriod(bp.uuid, { error: undefined });
    });
  },
});

export const useBudgetPeriodWizardStore = create(
  devtools(BudgetPeriodWizardStore),
);
