import { FC } from 'react';
import Link from 'next/link';
import { chakra } from '@chakra-ui/react';

interface BlockLinkProps {
  href?: string;
  label?: string;
}

// must be production because of how Notion links work
const MARKETING_DOMAIN = 'workreflex.com';

export const BlockLink: FC<BlockLinkProps> = ({ href = '', label = '' }) => {
  const createSafeLink = (href: string): string => {
    if (href.includes(MARKETING_DOMAIN)) {
      const safeLink = href.split(MARKETING_DOMAIN)[1];
      if (safeLink.charAt(0) === '/') return safeLink;
      return `/${safeLink}`;
    }
    return href;
  };
  const _href = createSafeLink(href);
  const isExternal = _href.charAt(0) !== '/' && _href.charAt(0) !== '#';
  const link = (
    <chakra.a
      href={isExternal ? _href : '#'}
      color="blue.500"
      transition="background 0.2s"
      _hover={{ bg: 'blue.100' }}
      target={isExternal ? '_blank' : undefined}
      referrerPolicy={isExternal ? 'no-referrer' : undefined}
      rel={isExternal ? 'noreferrer' : undefined}
    >
      {label}
    </chakra.a>
  );
  if (isExternal || _href.charAt(0) === '#') return link;
  return (
    <Link href={_href} passHref>
      {link}
    </Link>
  );
};
