// page - element - [context] - action

export enum Elements {
  BUTTON = 'Button',
  MENU_BUTTON = 'MenuButton',
  CARD = 'Card',
  FILTER_BUTTON = 'FilterButton',
  FORM = 'Form',
  HELPER_BUTTON = 'HelperButton',
  INPUT = 'Input',
  LABEL = 'Label',
  LINK = 'Link',
  MODAL = 'Modal',
  PAGE = 'Page',
  TAB = 'Tab',
  TAG = 'Tag',
  TEXT = 'Text',
  TILE_BUTTON = 'TileButton',
  TOGGLE = 'Toggle',
}

export enum Actions {
  CLICKED = 'Clicked',
  CLOSED = 'Closed',
  HIDDEN = 'Hidden',
  HOVERED = 'Hovered',
  LOADED = 'Loaded',
  OPENED = 'Opened',
  SCROLLED = 'Scrolled',
  SHOWN = 'Shown',
  SUBMITTED = 'Submitted',
}

export enum TrackingEvents {
  // WEB
  WORKER_SUBMITTED_SIGNUP = 'WorkerSubmittedSignup',
  WORKER_UPDATED_MARKET = 'WorkerUpdatedMarket',
  WORKER_UPLOADED_PHOTO = 'WorkerUploadedPhoto',
  WORKER_UPDATED_RETAIL_EXPERIENCE = 'WorkerUpdatedRetailExperience',
  WORKER_UPDATED_ADDITIONAL_EXPERIENCE = 'WorkerUpdatedAdditionalExperience',
  WORKER_UPDATED_EXPERIENCE_ROLE = 'WorkerUpdatedExperienceRole',
  WORKER_UPDATED_EXPERIENCE_BRAND = 'WorkerUpdatedExperienceBrand',
  WORKER_UPDATED_EXPERIENCE_DURATION = 'WorkerUpdatedExperienceDuration',
  WORKER_UPDATED_EXPERIENCE_SPECIFIC_DATES = 'WorkerUpdatedExperienceSpecificDates',
  WORKER_UPDATED_EXPERIENCE_LOCATION = 'WorkerUpdatedExperienceLocation',
  WORKER_UPDATED_EXPERIENCE_DETAILS = 'WorkerUpdatedExperienceDetails',
  WORKER_UPDATED_EXPERIENCE_EXTRA = 'WorkerUpdatedExperienceExtra',
  WORKER_FINISHED_EXPERIENCE = 'WorkerFinishedExperience',
  WORKER_UPDATED_SOCIAL_MEDIA = 'WorkerUpdatedSocialMedia',
  WORKER_UPDATED_BIRTHDATE = 'WorkerUpdatedBirthdate',
  WORKER_UPDATED_ADDRESS = 'WorkerUpdatedAddress',
  WORKER_UPDATED_SKILLS = 'WorkerUpdatedSkills',
  WORKER_SCHEDULED_CALL = 'WorkerScheduledCall',
  WORKER_OPENED_ADD_EXPERIENCE = 'WorkerOpenedAddExperience',
  WORKER_UPDATED_AVAILABILITY = 'WorkerUpdatedAvailability',
  WORKER_CLAIMED_FLEX = 'WorkerClaimedFlex',
  WORKER_DECLINED_FLEX = 'WorkerDeclinedFlex',
  WORKER_EXPRESSED_FLEX_INTEREST = 'WorkerExpressedFlexInterest',
  WORKER_STARTED_REVIEW = 'WorkerStartedReview',
  WORKER_PRESSED_ADD_TO_CALENDAR = 'WorkerPressedAddToCalendar',
  WORKER_VIEWED_FLEXES = 'WorkerViewedFlexes',
  WORKER_SKIPPED_REVIEW_QUESTION = 'WorkerSkippedReviewQuestion',
  WORKER_CLICKED_CANCEL_UPCOMING = 'WorkerClickedCancelUpcoming',
  WORKER_CLICKED_MAP = 'WorkerClickedMap',
  WORKER_UPDATED_PROFILE = 'WorkerUpdatedProfile',
  WORKER_CLICKED_SUPPORT = 'WorkerClickedSupport',
  WORKER_SUBMITTED_SUPPORT = 'WorkerSubmittedSupport',
  WORKER_ONBOARDING_FILLED_FIRST_NAME = 'WorkerOnboardingFilledFirstName',
  WORKER_ONBOARDING_FILLED_LAST_NAME = 'WorkerOnboardingFilledLastName',
  WORKER_ONBOARDING_FILLED_PHONE_NUMBER = 'WorkerOnboardingFilledPhoneNumber',
  WORKER_ONBOARDING_FILLED_REFERRAL_CODE = 'WorkerOnboardingFilledReferralCode',
  WORKER_ONBOARDING_FILLED_EMAIL = 'WorkerOnboardingFilledEmail',
  WORKER_ONBOARDING_VIEWED_ZIP_SETUP = 'WorkerOnboardingViewedZipSetup',
  WORKER_ONBOARDING_FILLED_ZIP = 'WorkerOnboardingFilledZip',
  WORKER_ONBOARDING_VIEWED_EXPERIENCE_PROMPT = 'WorkerOnboardingViewedExperiencePrompt',
  WORKER_ONBOARDING_CLICKED_WORKED_IN_RETAIL = 'WorkerOnboardingClickedWorkedInRetail',
  WORKER_ONBOARDING_CLICKED_NOT_YET = 'WorkerOnboardingClickedNotYet',
  WORKER_ONBOARDING_VIEWED_HISTORY = 'WorkerOnboardingViewedHistory',
  WORKER_ONBOARDING_FILLED_OPEN_ENDED = 'WorkerOnboardingFilledOpenEnded',
  WORKER_ONBOARDING_FILLED_WHY_REFLEX = 'WorkerOnboardingFilledWhyReflex',
  WORKER_ONBOARDING_VIEWED_BRAND_LIST = 'WorkerOnboardingViewedBrandList',
  WORKER_ONBOARDING_CLICKED_NOT_LISTED = 'WorkerOnboardingClickedNotListed',
  WORKER_ONBOARDING_VIEWED_ROLE_LIST = 'WorkerOnboardingViewedRoleList',
  WORKER_ONBOARDING_ENTERED_ROLE = 'WorkerOnboardingEnteredRole',
  WORKER_ONBOARDING_VIEWED_ROLE_LENGTH = 'WorkerOnboardingViewedRoleLength',
  WORKER_ONBOARDING_ENTERED_ROLE_LENGTH = 'WorkerOnboardingEnteredRoleLength',
  WORKER_ONBOARDING_VIEWED_ADD_ANYTHING_ELSE = 'WorkerOnboardingViewedAddAnythingElse',
  WORKER_ONBOARDING_CLICKED_ADD_MORE_RETAIL_EXPERIENCE = 'WorkerOnboardingClickedAddMoreRetailExperience',
  WORKER_ONBOARDING_CLICKED_FINISH_ADDING_EXPERIENCE = 'WorkerOnboardingClickedFinishAddingExperience',
  WORKER_ONBOARDING_VIEWED_BIRTHDAY = 'WorkerOnboardingViewedBirthday',
  WORKER_ONBOARDING_FILLED_BIRTHDAY = 'WorkerOnboardingFilledBirthday',
  WORKER_ONBOARDING_VIEWED_PHOTO_UPLOAD = 'WorkerOnboardingViewedPhotoUpload',
  WORKER_ONBOARDING_UPLOADED_PHOTO = 'WorkerUploadedPhoto',
  WORKER_ONBOARDING_CLICKED_EDIT_PHOTO = 'WorkerOnboardingClickedEditPhoto',
  WORKER_ONBOARDING_APPROVED_PHOTO = 'WorkerOnboardingApprovedPhoto',
  WORKER_ONBOARDING_CLICKED_PROFILE_PHOTO = 'WorkerOnboardingClickedProfilePhoto',
  WORKER_ONBOARDING_CLICKED_EXPERIENCE = 'WorkerOnboardingClickedExperience',
  WORKER_ONBOARDING_CLICKED_SOCIAL_MEDIA = 'WorkerOnboardingClickedSocialMedia',
  WORKER_ONBOARDING_CLICKED_ADDRESS = 'WorkerOnboardingClickedAddress',
  WORKER_ONBOARDING_CLICKED_SKILLS = 'WorkerOnboardingClickedSkills',

  RETAILER_PRESSED_ADD_TO_CALENDAR = 'RetailerPressedAddToCalendar',
  RETAILER_UNFAVORITED_WORKER = 'RetailerUnfavoritedWorker',
  RETAILER_FAVORITED_WORKER = 'RetailerFavoritedWorker',
  RETAILER_UNBLOCKED_WORKER = 'RetailerUnblockedWorker',
  RETAILER_BLOCKED_WORKER = 'RetailerBlockedWorker',
  RETAILER_SUBMITTED_FLEX_REQUEST = 'RetailerSubmittedFlexRequest',
  RETAILER_USED_FLEX_REQUEST_CALENDAR = 'RetailerUsedFlexRequestCalendar',
  RETAILER_VIEWED_FLEX = 'RetailerViewedFlex',
  RETAILER_CLICKED_CANCEL_UPCOMING = 'RetailerClickedCancelUpcoming',
  RETAILER_CANCELLED_SHIFT = 'RetailerCancelledShift',
  RETAILER_VIEWED_PAST_FLEXES = 'RetailerViewedPastFlexes',
  RETAILER_PRESSED_LEAVE_REVIEW = 'RetailerPressedLeaveReview',
  RETAILER_USED_REPORTS_CALENDAR = 'RetailerUsedReportsCalendar',
  RETAILER_SELECTED_REPORT_RANGE = 'RetailerSelectedReportRange',
  RETAILER_SELECTED_REPORT_LOCATION = 'RetailerSelectedReportLocation',
  RETAILER_SKIPPED_REVIEW_QUESTION = 'RetailerSkippedReviewQuestion',
  RETAILER_STARTED_REVIEW = 'RetailerStartedReview',
  RETAILER_OPENED_REVIEW_MODAL = 'RetailerOpenedReviewModal',
  RETAILER_SUBMITTED_REVIEW_MODAL = 'RetailerSubmittedReviewModal',
  RETAILER_SUBMITTED_REVIEW_FULL = 'RetailerSubmittedReviewFull',
  RETAILER_CLICKED_SUPPORT = 'RetailerClickedSupport',
  RETAILER_SUBMITTED_SUPPORT = 'RetailerSubmittedSupport',
  RETAILER_REVIEW_MODAL = 'RetailerReviewModal',
}
