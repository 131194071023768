import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { getStore, REFLEX_STORE, updateReflexStoreWeb } from 'helpers';
import { isEmpty } from 'lodash';
import { ReflexStoreWeb } from 'types';

interface AnalyticsInterface {
  store?: ReflexStoreWeb;
  updateStore?: (data: Partial<ReflexStoreWeb>) => void;
}

export const useReflexStoreWeb = (): AnalyticsInterface => {
  const { query } = useRouter();
  const [currentStore, setCurrentStore] = useState<Partial<ReflexStoreWeb>>({});
  const [canUpdate, setCanUpdate] = useState(false);

  const trackedStore = useMemo(() => getStore(REFLEX_STORE) || {}, []);
  useEffect(() => {
    setCurrentStore(trackedStore);
  }, [trackedStore]);
  useEffect(() => {
    setCanUpdate(true);
  }, []);

  const handleUpdateStore = (data: Partial<ReflexStoreWeb>) => {
    setCurrentStore({
      user: { ...currentStore?.user, ...data.user },
      meta: { ...currentStore?.meta, ...data.meta },
      preferences: { ...currentStore?.preferences, ...data.preferences },
      tags: Array.from(
        new Set([...(currentStore?.tags || []), ...(data.tags || [])]),
      ),
    });
  };

  useEffect(() => {
    // update local storage when state changes
    updateReflexStoreWeb(currentStore);
  }, [currentStore]);

  useEffect(() => {
    // Update store from query
    if (!canUpdate) return;
    if (isEmpty(query)) return;

    const { source, src, utm_src } = query;

    source &&
      updateReflexStoreWeb({ meta: { last_source: source.toString() } });
    src && updateReflexStoreWeb({ meta: { last_source: src.toString() } });
    utm_src &&
      updateReflexStoreWeb({ meta: { last_source: utm_src.toString() } });
  }, [canUpdate, query]);

  return {
    store: currentStore,
    updateStore: handleUpdateStore,
  };
};
