import { applyContext, fetchObject, Queries } from 'helpers';
import { GQL, ReflexResponse } from 'types';

export const fetchUserFlagConfigs = async (
  query = Queries.USER_FLAG_CONFIGS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = 'userFlagConfigs',
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, contextOverride || 'USER_FLAG_CONFIG_QUERY'),
    query,
    data: {},
    returnKey,
  });
