import { FC } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  Text,
  TextProps,
  Tooltip,
} from '@chakra-ui/react';
import { getLocationNameForDisplay } from 'helpers';
import { location } from 'types';

interface LocationNameProps extends location {
  showLogo?: boolean;
  containerProps?: BoxProps;
  wrapperProps?: BoxProps;
  hideBrandName?: boolean;
  brandNameProps?: TextProps;
  brandImageProps?: BoxProps;
  locationNameProps?: TextProps;
  truncateNameOnCharacterWidth?: number;
}

export const LocationName: FC<LocationNameProps> = ({
  showLogo = true,
  containerProps,
  wrapperProps,
  hideBrandName = false,
  brandNameProps,
  brandImageProps,
  locationNameProps,
  truncateNameOnCharacterWidth,
  ...location
}) => {
  const locationNameCharacterCount = location.name?.length;
  const willTruncate =
    !!truncateNameOnCharacterWidth &&
    locationNameCharacterCount > truncateNameOnCharacterWidth + 3;

  const Label = (
    <Flex
      flexDirection="column"
      align="baseline"
      lineHeight="shorter"
      flexWrap="wrap"
      columnGap={2}
      {...(truncateNameOnCharacterWidth
        ? {
            width: '100%',
            maxWidth: `${truncateNameOnCharacterWidth}ch`,
            overflow: 'hidden',
          }
        : {})}
      {...wrapperProps}
    >
      {!hideBrandName && (
        <Text
          fontWeight="medium"
          fontSize="lg"
          whiteSpace="break-spaces"
          color="blackAlpha.800"
          {...brandNameProps}
        >
          {location.retailer.name}
        </Text>
      )}
      <Text
        color="text.hint"
        fontSize="xs"
        fontWeight="semi"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        {...(truncateNameOnCharacterWidth
          ? {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: `${truncateNameOnCharacterWidth}ch`,
            }
          : {})}
        {...locationNameProps}
      >
        {location.name}
      </Text>
    </Flex>
  );

  const LabelWithTooltip = (
    <Tooltip
      label={getLocationNameForDisplay(location, {
        displayRetailerName: !hideBrandName,
      })}
      aria-label={getLocationNameForDisplay(location, {
        displayRetailerName: !hideBrandName,
      })}
    >
      {Label}
    </Tooltip>
  );

  const imageUrlRoot = process.env.NEXT_PUBLIC_AWS_APP_S3_URL
    ? `${process.env.NEXT_PUBLIC_AWS_APP_S3_URL}/`
    : process.env.NEXT_PUBLIC_BUCKET_URL;

  return (
    <Flex {...containerProps}>
      {showLogo && (
        <Box
          display={{ base: 'none', md: 'flex' }}
          position="relative"
          overflow="hidden"
          backgroundColor="transparent"
          backgroundSize="cover"
          backgroundPosition="center center"
          backgroundImage={`url(${imageUrlRoot}${location.retailer.logoImage})`}
          h={9}
          w={12}
          flexShrink={0}
          {...brandImageProps}
        />
      )}
      <Flex flexDirection="column">
        {truncateNameOnCharacterWidth && willTruncate
          ? LabelWithTooltip
          : Label}
      </Flex>
    </Flex>
  );
};
