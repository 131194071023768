import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { validate as uuidValidate } from 'uuid';
import ReflexAPI, { redirectIfMaintenanceMode } from 'helpers/src/api';
import { USER_TOKEN } from 'types/src/config';
import {
  API_USERS_ME,
  OPS_HOME,
  OPS_LOGIN,
  WEB_APP_LOGIN,
} from 'types/src/routes';

const REFLEX_STAFF_USER_TYPE = 'STAFF';

export const getDefaultServerHeaders = (token: string) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
    'X-Authorization': `Token ${token}`,
  };
};

export function requireStaffAuthentication(getServerSideProps?) {
  return async context => {
    const { req } = context;
    const token = req.cookies[USER_TOKEN];

    if (!token) {
      return {
        redirect: {
          destination: OPS_LOGIN,
          statusCode: 302,
        },
      };
    }

    const options = {
      headers: {
        'X-Authorization': `Token ${token}`,
      },
    };

    try {
      const authResponse = await ReflexAPI.get(API_USERS_ME, options)
        .then(redirectIfMaintenanceMode)
        .then(response => response.json())
        .then(self => {
          if (self.user_type !== REFLEX_STAFF_USER_TYPE) {
            return {
              redirect: {
                destination: OPS_LOGIN,
                statusCode: 302,
              },
            };
          } else if (getServerSideProps) {
            return getServerSideProps(context);
          }
          return { props: {} };
        });
      return authResponse;
    } catch (error) {
      console.error(error);
      if (process.env.NODE_ENV !== 'production') {
        return { props: {} };
      }
      return {
        redirect: {
          destination: WEB_APP_LOGIN,
          statusCode: 302,
        },
      };
    }
  };
}

const fetchAPIMe = async (token: string) => {
  try {
    return await fetch(API_USERS_ME, {
      method: 'GET',
      headers: getDefaultServerHeaders(token),
    });
  } catch (error) {
    console.error(error);
  }
};

interface serverSideRedirectToLoginProps {
  after?: string;
  context?: GetServerSidePropsContext;
}
export const serverSideRedirectToLogin = ({
  after,
  context,
}: serverSideRedirectToLoginProps = {}): GetServerSidePropsResult<unknown> => {
  const continuePath = after ? after : context ? context.resolvedUrl : null;
  const destination = `${OPS_HOME}${!continuePath ? '' : `?ops-redirect=${encodeURIComponent(continuePath)}`}`;
  return {
    redirect: {
      destination,
      statusCode: 302,
    },
  };
};

export const getUserToken = (context: GetServerSidePropsContext) => {
  const { req } = context;
  return req.cookies[USER_TOKEN];
};

export const isStaffAuthenticated = async (
  context: GetServerSidePropsContext,
) => {
  const token = getUserToken(context);
  if (!token) return false;
  const authResponse = await fetchAPIMe(token);
  const responseCode = authResponse.status;
  if (responseCode >= 400) return false;
  const { user_type } = await authResponse.json();
  if (user_type !== REFLEX_STAFF_USER_TYPE) return false;
  return true;
};

export const pathHasUuid = (context: GetServerSidePropsContext) => {
  const { uuid } = context.params;
  if (!uuid || !uuidValidate(uuid)) return false;
  return true;
};
