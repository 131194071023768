import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { GQL, paging, Prettify, ReflexResponse } from 'types';

export type fetchLocationBudgetsSubNodeProps = {
  startFromDate?: string; // Date
  startToDate?: string; // Date
  endFromDate?: string; // Date
  endToDate?: string; // Date
};

export type fetchLocationBudgetsProps = Prettify<
  paging &
    fetchLocationBudgetsSubNodeProps & {
      isCurrent?: boolean;
      locations?: string[]; // Location uuids
      hasUnapproved?: boolean;
    }
>;

export const fetchLocationBudgets = async (
  data: fetchLocationBudgetsProps,
  query = Queries.LOCATION_BUDGETS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['locationBudgets', 'locationBudgets_paging'],
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, contextOverride || 'LOCATION_BUDGETS_QUERY'),
    query,
    data,
    returnKey,
  });

export interface mutateCreateLocationBudgetProps {
  location: string;
  start: string;
  end: string;
  amount: number;
}

export const mutateCreateLocationBudget = async (
  data: mutateCreateLocationBudgetProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'CREATE_LOCATION_BUDGET_MUTATION'),
    query: Mutations.CREATE_LOCATION_BUDGET_MUTATION,
    data,
    returnKey: 'createLocationBudget',
  });
};

export interface mutateUpdateLocationBudgetProps {
  uuid: string;
  amountHours?: number; // Number
  note?: string;
}

export const mutateUpdateLocationBudget = async (
  data: mutateUpdateLocationBudgetProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'UPDATE_LOCATION_BUDGET_MUTATION'),
    query: Mutations.UPDATE_LOCATION_BUDGET_MUTATION,
    data,
    returnKey: 'updateLocationBudget',
  });
};

export interface mutateApproveShiftsProps {
  shifts?: string[];
  cancelShifts?: string[];
  jwt?: string;
}

export const mutateApproveShifts = async (data: mutateApproveShiftsProps) => {
  return await mutateObject({
    route: applyContext(GQL, 'APPROVE_SHIFTS_MUTATION'),
    query: Mutations.APPROVE_SHIFTS_MUTATION,
    data,
    returnKey: 'approveShifts',
  });
};

export interface fetchBudgetPeriodsProps extends paging {
  startFromDate?: string; // Date
  startToDate?: string; // Date
  endFromDate?: string; // Date
  endToDate?: string; // Date
  isCurrent?: boolean;
  excludePast?: boolean;
  locations?: string[]; // Location uuids
  organizations?: string[]; // Organization uuids
}

export const fetchBudgetPeriods = async (
  data: fetchBudgetPeriodsProps,
  query = Queries.BUDGET_PERIODS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['budgetPeriods', 'budgetPeriods_paging'],
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, contextOverride || 'BUDGET_PERIODS_QUERY'),
    query,
    data,
    returnKey,
  });

export interface mutateCreateBudgetPeriodsProps {
  organizationUuid: string;
  budgetPeriods: {
    start: string;
    end: string;
    name: string;
  }[];
  createEmptyBudgets: boolean;
}

export const mutateCreateBudgetPeriods = async (
  data: mutateCreateBudgetPeriodsProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'CREATE_BUDGET_PERIODS_MUTATION'),
    query: Mutations.CREATE_BUDGET_PERIODS_MUTATION,
    data,
    returnKey: 'createBudgetPeriods',
  });
};
