import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NEXT_PUBLIC_REFLEX_SOCKET_URL;
const isBrowser = typeof window !== 'undefined';
export const socket = isBrowser
  ? io(URL, {
      autoConnect: false,
    })
  : undefined;
