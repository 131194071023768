import { FC } from 'react';
import { Flex, FlexProps, Tooltip } from '@chakra-ui/react';
import { Icon } from 'icons';
import {
  LocationPermissionDescriptions,
  LocationPermissionIcons,
  LocationPermissionLabels,
} from 'types';
import { TextAndIcon } from 'ui';

export enum PermissionOptionState {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

const settings = {
  [PermissionOptionState.POSITIVE]: {
    backgroundColor: 'green.50',
    color: 'green.500',
    icon: 'check',
    iconColor: 'green.500',
  },
  [PermissionOptionState.NEGATIVE]: {
    backgroundColor: 'blackAlpha.50',
    color: 'blackAlpha.600',
    icon: 'x',
    iconColor: 'blackAlpha.400',
  },
};

interface PermissionCardProps {
  state?: PermissionOptionState;
  permission: string; // LocationPermission
  handleUpdateState: (permission: string) => void;
  containerProps?: FlexProps;
  isDisabled?: boolean;
}

export const PermissionCard: FC<PermissionCardProps> = ({
  state = PermissionOptionState.NEGATIVE,
  permission,
  handleUpdateState,
  containerProps,
  isDisabled = true,
}) => {
  const getSettings = () => settings[state];
  const onUpdate = () => handleUpdateState(permission);
  // const onUpdate = () => null;

  const card = (
    <Flex
      className="card"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      backgroundColor={getSettings().backgroundColor}
      color={getSettings().color}
      borderRadius="md"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      p={2}
      pr={4}
      onClick={isDisabled ? () => null : onUpdate}
      userSelect="none"
      {...containerProps}
    >
      {getSettings().icon && (
        <Icon
          name={getSettings().icon}
          color={getSettings().iconColor}
          h="24px"
          w="24px"
        />
      )}
      <TextAndIcon
        text={LocationPermissionLabels[permission]}
        textProps={{ ml: 1.5, fontWeight: 'medium' }}
        icon={LocationPermissionIcons[permission]}
        iconProps={{ h: 5, w: 5, ml: 1 }}
        containerProps={{ flexGrow: 1 }}
      />
    </Flex>
  );

  return (
    <Tooltip
      label={LocationPermissionDescriptions[permission]}
      openDelay={1000} // ms
      gutter={0}
    >
      {card}
    </Tooltip>
  );
};
