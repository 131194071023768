export const CREATE_LOCATION_BUDGET_MUTATION = `
  mutation CREATE_LOCATION_BUDGET_MUTATION(
    $start: Date
    $end: Date
    $location: String
    $amount: Float
  ) {
    createLocationBudget(
      start: $start
      end: $end
      location: $location
      amount: $amount
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const UPDATE_LOCATION_BUDGET_MUTATION = `
  mutation UPDATE_LOCATION_BUDGET_MUTATION(
    $uuid: String
    $amountHours: Decimal
    $note: String
  ) {
    updateLocationBudget(
      uuid: $uuid
      amountHours: $amountHours
      note: $note
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const APPROVE_SHIFTS_MUTATION = `
  mutation APPROVE_SHIFTS_MUTATION(
    $shifts: [String]
    $cancelShifts: [String]
  ) {
    approveShifts(
      shifts: $shifts
      cancelShifts: $cancelShifts
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

// input BudgetPeriodInput {
//   start: Date
//   end: Date
//   name: String
// }
export const CREATE_BUDGET_PERIODS_MUTATION = `
  mutation CREATE_BUDGET_PERIODS_MUTATION(
    $budgetPeriods: [BudgetPeriodInput]!
    $organizationUuid: String!
    $createEmptyBudgets: Boolean
  ) {
    createBudgetPeriods(
      budgetPeriods: $budgetPeriods
      organizationUuid: $organizationUuid
      createEmptyBudgets: $createEmptyBudgets
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;
