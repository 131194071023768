import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

export const _REVIEWS_PARAMS = `
  $createdSince: Date
  $createdTo: Date
  $hasCoaching: Boolean
  $hasFeedback: Boolean
  $hasPrioritization: Boolean
  $isUrgent: Boolean
  $locations: [String]
  $markets: [Int]
  $prioritization: Int
  $priority: [String]
  $rating: [Int]
  $retailers: [String]
  $reviewType: String
  $status: [String]
  $updatedSince: Date
  $updatedTo: Date
  $uuid: String
  $workers: [String]
  ${_PAGING_PARAMS}
`;

export const _REVIEWS_VARIABLES = `
  createdSince: $createdSince
  createdTo: $createdTo
  hasCoaching: $hasCoaching
  hasFeedback: $hasFeedback
  hasPrioritization: $hasPrioritization
  isUrgent: $isUrgent
  locations: $locations
  markets: $markets
  prioritization: $prioritization
  priority: $priority
  rating: $rating
  retailers: $retailers
  reviewType: $reviewType
  status: $status
  updatedSince: $updatedSince
  updatedTo: $updatedTo
  uuid: $uuid
  workers: $workers
  ${_PAGING_VARIABLES}
`;

export const _REVIEWS_META_VARIABLES = `
  meta: {
    averageRating: {
      avg: "rating"
    },
    averagePrioritization: {
      avg: "prioritization"
    },
    completedCount: { 
      count: "status",
      in: ["PUBLISHED", "ARCHIVED"]
    }
    publishedCount: { 
      count: "status",
      is: "PUBLISHED"
    }
    internalReviewCount: { 
      count: "status",
      is: "PENDING"
    }
    archivedCount: { 
      count: "status",
      is: "ARCHIVED"
    }
  }
`;

export const ENDORSEMENTS_QUERY = `
  query ENDORSEMENTS_QUERY {
    endorsements {
      id
      name
      icon
      priority
    }
  }
`;

export const GROWTH_AREAS_QUERY = `
  query GROWTH_AREAS_QUERY {
    growthAreas {
      id
      name
      icon
      priority
      category
    }
  }
`;

export const REVIEW_QUERY = `
  query REVIEW_QUERY(
    $id: Int,
  ) {
    review (id: $id) {
      id
      feedback
      coaching
      prioritization
      recommended
      rating
      status
      workershift {
        uuid
        worker {
          firstName
          lastName
          profileImg
          uuid
          eritId
        }
        shift {
          start
          end
          status
          uuid
          workerRoles {
            id
            name
            icon
          }
          retailerLocation {
            name
            uuid
            timezone
            retailer {
              logoImage
              name
              uuid
            }
          }
        }
      }
    }
  }
`;

export const WORKER_REVIEW_QUERY = `
  query REVIEW_QUERY(
    $id: Int,
  ) {
    review (id: $id) {
      id
      rating
      ratingDetails
      activities {
        id
        name
      }
      activitiesDetails
      repeat
      repeatDetails
      status
      workershift {
        uuid
        worker {
          firstName
          lastName
          profileImg
          uuid
        }
        shift {
          start
          end
          status
          uuid
          workerRoles {
            id
            name
            icon
          }
          retailerLocation {
            name
            uuid
            timezone
            retailer {
              logoImage
              name
              uuid
            }
          }
        }
      }
    }
  }
`;

export const WORKER_REVIEW_SHIFT_ACTIVITIES_QUERY = `
  query REVIEW_QUERY(
    $isActive: Boolean,
  ) {
    activities (isActive: $isActive) {
      icon
      id
      name
    }
  }
`;

export const REVIEWS_LIST_QUERY = `
  query REVIEWS_LIST_QUERY(${_REVIEWS_PARAMS}) {
    reviews(${_REVIEWS_VARIABLES}) {
      id
      uuid
      coaching
      createdAt
      feedback
      isUrgent
      priority
      prioritization
      rating
      ratingDetails
      recommended
      repeat
      repeatDetails
      reviewType
      status
      activities {
        id
        name
      }
      activitiesDetails
      reviewer {
        firstName
        lastName
        preferredName
        uuid
        profileImg
      }
      endorsements {
        name
        icon
        priority
      }
      growthAreas {
        name
        icon
        priority
        category
      }
      workershift {
        uuid
        worker {
          firstName
          lastName
          preferredName
          profileImg
          uuid
          eritId
        }
        shift {
          start
          end
          status
          uuid
          workerRoles {
            id
            name
            icon
          }
          retailerLocation {
            name
            billingName
            uuid
            timezone
            retailer {
              logoImage
              name
              uuid
            }
          }
        }
      }
    }
  }
`;

export const REVIEWS_LIST_META_QUERY = `
  query REVIEWS_META_QUERY(${_REVIEWS_PARAMS}) {
    reviews(${_REVIEWS_VARIABLES}${_REVIEWS_META_VARIABLES}) {
      id
      uuid
    }
  }
`;

export const WORKER_REVIEWS_LIST_QUERY = `
  query WORKER_REVIEWS_LIST_QUERY(${_REVIEWS_PARAMS}) {
    reviews(${_REVIEWS_VARIABLES}) {
      id
      workershift {
        shift {
          retailerLocation {
            name
            retailer {
              name
            }
          }
        }
      }
    }
  }
`;

export const USER_PROFILE_FOR_RETAILER_REVIEWS = `
  query USER_PROFILE_FOR_RETAILER_REVIEWS(${_REVIEWS_PARAMS}) {
    reviews(${_REVIEWS_VARIABLES}) {
      createdAt
      id
      rating
      status
      coaching
      feedback
      prioritization
      reviewer {
        firstName
        lastName
        preferredName
        uuid
        profileImg
      }
      endorsements {
        name
        icon
        priority
      }
      growthAreas {
        name
        icon
        priority
        category
      }
      workershift {
        shift {
          start
          retailerLocation {
            name
            retailer {
              name
              logoImage
            }
          }
        }
      }
    }
  }
`;
