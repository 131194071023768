import { useRouter } from 'next/router';
import { filterNullsFromObject } from 'helpers';

export enum RouterOptions {
  PUSH = 'push',
  REPLACE = 'replace',
}

type queryParams = Record<string, string>;

interface useQueryParamsHelperReturn {
  pathname: string;
  query: Record<string, string | string[]>;
  updateQueryParams: (
    params: queryParams,
    routerOption?: RouterOptions,
  ) => void;
}

export const useQueryParamsHelper = (): useQueryParamsHelperReturn => {
  const router = useRouter();
  const { pathname, query } = router;

  const updateQueryParams = (
    params: queryParams,
    routerOption: RouterOptions = RouterOptions.PUSH,
  ) => {
    router[routerOption](
      {
        pathname: pathname,
        query: filterNullsFromObject({
          ...query,
          ...params,
        }),
      },
      undefined,
      { scroll: false, shallow: true },
    );
  };

  return {
    pathname,
    query,
    updateQueryParams,
  };
};
