import { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';
import { OPS_ROUTES } from 'types/src/routes';

interface NavItem {
  label: string;
  href: string;
  context?: 'top' | 'drawer';
  isActive?: boolean;
}

const styleConfig = {
  top: {
    colorFont: 'main.white',
    colorBackground: 'whiteAlpha.200',
    colorBackgroundHover: 'whiteAlpha.300',
    colorBackgroundFocus: 'whiteAlpha.300',
    colorBackgroundActive: 'whiteAlpha.400',
    fontSize: { base: 'sm', sm: 'md', lg: 'lg' },
    px: { base: 2, lg: 3 },
    py: 1,
    width: 'initial',
  },
  drawer: {
    colorFont: 'main.navy',
    colorBackground: 'blackAlpha.100',
    colorBackgroundHover: 'blackAlpha.200',
    colorBackgroundFocus: 'blackAlpha.200',
    colorBackgroundActive: 'blackAlpha.300',
    fontSize: { base: 'xl', sm: 'md', lg: 'lg' },
    px: 3,
    py: 2,
    width: '100%',
  },
};

const getStyleByContext = context => styleConfig[context];

const getLinkStyles = (context, isActive) => ({
  fontWeight: 'medium',
  transition: 'background 0.2s',
  rounded: 'lg',
  px: getStyleByContext(context).px,
  py: getStyleByContext(context).py,
  color: getStyleByContext(context).colorFont,
  width: getStyleByContext(context).width,
  fontSize: getStyleByContext(context).fontSize,
  backgroundColor: isActive
    ? getStyleByContext(context).colorBackground
    : 'transparent',
  _hover: { backgroundColor: getStyleByContext(context).colorBackgroundHover },
  _focus: { backgroundColor: getStyleByContext(context).colorBackgroundFocus },
  _active: {
    backgroundColor: getStyleByContext(context).colorBackgroundActive,
  },
});

export const NavItem: FC<NavItem> = ({ label, href, context = 'top' }) => {
  const [isActive, setIsActive] = useState(false);
  const router = useRouter();
  const { asPath } = router;
  const isExternal = href?.charAt(0) !== '/' && href?.charAt(0) !== '#';

  useEffect(() => {
    const isHome = href === OPS_ROUTES.home;
    const isHomeActive = isHome && asPath === href;
    const shouldActiveHome = isHome && isHomeActive;
    if (shouldActiveHome) setIsActive(true);
    else if (!isHome && (asPath === href || asPath.includes(href)))
      setIsActive(true);
    else setIsActive(false);
  }, [asPath, href]);

  if (isExternal)
    return (
      <Box
        {...getLinkStyles(context, isActive)}
        href={href}
        as="a"
        target="_blank"
        referrerPolicy="no-referrer"
        cursor="pointer"
        userSelect="none"
      >
        <a href={href}>{label}</a>
      </Box>
    );

  return (
    <Box {...getLinkStyles(context, isActive)}>
      <Link href={href} passHref>
        {label}
      </Link>
    </Box>
  );
};
