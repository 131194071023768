import { useRouter } from 'next/router';
import { getParsedAttributionFromStorage } from 'helpers';
import { Actions, Elements, TrackingEvents } from 'types';
import {
  getPageNameFromUrl,
  getWildcardArgPositions,
  splitUrlByRoute,
} from 'helpers/src/analytics';
import { APP_ROUTES } from 'types/src/routes';
import { useReflexStoreWeb } from './useReflexStore';

export { Actions, Elements };

const DUPLICATE_PAGE_EVENT_THRESHOLD =
  Number.parseInt(process?.env?.NEXT_PUBLIC_PAGE_EVENT_THRESHOLD) || 0;
const DUPLICATE_IDENTIFY_EVENT_THRESHOLD =
  Number.parseInt(process?.env?.NEXT_PUBLIC_IDENTIFY_EVENT_THRESHOLD) || 0;

interface identifyEventProps {
  id: string;
  traits?: any;
}
interface trackEventProps {
  eventName: TrackingEvents;
  traits?: any;
  includePageSource?: boolean;
}

// Extend global window with Segment analytics
declare const window: Window &
  typeof globalThis & {
    analytics?: any;
  };

export enum REFLEX_PROJECTS {
  WEB = 'WEB',
  OPS = 'OPs',
}

type useEventsProps = {
  project?: REFLEX_PROJECTS;
};

type useEventsReturn = {
  identifyEvent?: (props: identifyEventProps) => void;
  pageEvent?: () => void;
  trackEvent?: (props: trackEventProps) => void;
};

const defaultProps = {
  project: REFLEX_PROJECTS.WEB,
};

export const useEvents = (
  props: useEventsProps = defaultProps,
): useEventsReturn => {
  const { project = defaultProps.project } = props;
  const { store, updateStore } = useReflexStoreWeb();
  const { route } = useRouter();
  if (
    typeof window === 'undefined' ||
    !window ||
    !window?.analytics ||
    !process.env.NEXT_PUBLIC_SEGMENT_ENABLED
  ) {
    // if script disabled or failed to load, return noop functions
    () => null;
    () => null;
    () => null;
  }

  const pageEvent = (): void => {
    const fullUrl = window.location.href;

    const splitUrl = splitUrlByRoute(fullUrl, route);

    const wildcardVariables = getWildcardArgPositions(route).reduce(
      (wildcardArgs, argPosition) => {
        return {
          ...wildcardArgs,
          [`wc_${argPosition.argName}`]: splitUrl[argPosition.index],
        };
      },
      {},
    );

    const pageName = getPageNameFromUrl(route, APP_ROUTES); // FUTURE: extend for ops

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(
      Array.from(urlSearchParams.entries()).map(kvPair => [
        `qp_${kvPair[0]}`,
        kvPair[1],
      ]),
    );

    const pageEventInfo = {
      ...wildcardVariables,
      ...params,
      url: fullUrl,
      route,
      ...(project === REFLEX_PROJECTS.WEB && {
        lastPage: store?.meta?.lastPage,
        messengerAttributionData: getParsedAttributionFromStorage(),
      }),
    };

    if (!isDuplicatePageEvent(fullUrl)) {
      if (process.env.NEXT_PUBLIC_SEGMENT_ENABLED)
        window?.analytics?.page(pageName, pageEventInfo);

      // eslint-disable-next-line no-console
      if (process.env.NEXT_PUBLIC_SEGMENT_DEBUG)
        console.log(`PAGE EVENT -- ${pageName}`, pageEventInfo);
    }
    updatePageEventHistory(fullUrl);
  };

  const trackEvent = ({
    eventName,
    traits,
    includePageSource = false,
  }: trackEventProps): void => {
    const messengerAttributionData = getParsedAttributionFromStorage();
    const fullTraits = includePageSource
      ? {
          ...traits,
          pageSource: getPageNameFromUrl(route, APP_ROUTES),
          pageSourceUrl: window.location.href,
          messengerAttributionData,
        }
      : traits;
    if (process.env.NEXT_PUBLIC_SEGMENT_ENABLED)
      window?.analytics?.track(eventName, fullTraits);

    // eslint-disable-next-line no-console
    if (process.env.NEXT_PUBLIC_SEGMENT_DEBUG)
      console.log(`TRACK EVENT -- ${eventName}`, fullTraits);
  };

  const identifyEvent = ({ id, traits = null }: identifyEventProps): void => {
    if (!isDuplicateIdentifyEvent()) {
      if (process.env.NEXT_PUBLIC_SEGMENT_ENABLED)
        window?.analytics?.identify(id, traits);

      // eslint-disable-next-line no-console
      if (process.env.NEXT_PUBLIC_SEGMENT_DEBUG)
        console.log(`IDENTIFY EVENT -- ${id}`, traits);
    }
    const timestamp = new Date();
    updateStore({
      meta: { ...store.meta, lastIdentifyEvent: timestamp.toString() },
    });
  };

  const updatePageEventHistory = fullUrl => {
    const timestamp = new Date();
    const pageHistory = store?.meta?.pageHistory
      ? { ...store.meta.pageHistory, [fullUrl]: timestamp.toString() }
      : { [fullUrl]: timestamp.toString() };
    updateStore({
      meta: { ...store.meta, pageHistory: pageHistory, lastPage: fullUrl },
    });
  };

  // Future: Should take into account which traits are being updated because
  // we may want to start sending more of these in the future
  const isDuplicateIdentifyEvent = () => {
    const nowTimestamp = new Date();
    if (!store?.meta?.lastIdentifyEvent) {
      return false;
    }
    const prevTimestamp = new Date(store.meta.lastIdentifyEvent);
    return (
      nowTimestamp.getTime() / 1000 - prevTimestamp.getTime() / 1000 <
      DUPLICATE_IDENTIFY_EVENT_THRESHOLD
    );
  };
  const isDuplicatePageEvent = name => {
    const nowTimestamp = new Date();
    if (!store?.meta?.pageHistory?.[name]) {
      return false;
    }
    const prevTimestamp = new Date(store.meta.pageHistory[name]);
    return (
      nowTimestamp.getTime() / 1000 - prevTimestamp.getTime() / 1000 <
      DUPLICATE_PAGE_EVENT_THRESHOLD
    );
  };

  return {
    identifyEvent,
    pageEvent,
    trackEvent,
  };
};
