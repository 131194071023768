import { FC } from 'react';
import Link from 'next/link';
import {
  Box,
  BoxProps,
  Flex,
  HStack,
  IconButton,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import { RouterOptions, useViewHelper } from 'hooks';
import { Icon, ICON_NAMES } from 'icons';
import { QueryParamDrawer } from 'types';
import { LogoSpecial } from 'ui';
import { OPS_HOME, OPS_MAIN } from 'types/src/routes';
import { NotificationPopover } from 'components/notifications';
import { navOptions } from './nav.data';
import { NavDrawer } from './NavDrawer';
import { NavItem } from './NavItem';

const IS_DEMO = process.env.NEXT_PUBLIC_IS_DEMO === 'true';

interface TopNavigationProps {
  containerProps?: BoxProps;
}

export const TopNavigation: FC<TopNavigationProps> = ({ containerProps }) => {
  const { handleOpenDrawer } = useViewHelper();
  const openMenuDrawer = () =>
    handleOpenDrawer(QueryParamDrawer.MENU, RouterOptions.REPLACE);

  const environment = IS_DEMO
    ? 'demo'
    : process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
      ? 'staging'
      : process.env.NODE_ENV === 'development'
        ? 'dev'
        : null;

  return (
    <>
      <Box
        as="header"
        w="full"
        overflowX="hidden"
        zIndex={1}
        {...containerProps}
      >
        <Box
          maxW="6xl"
          mx="auto"
          py={{ base: 1, md: 2 }}
          px={{ base: 2, md: 2 }}
        >
          <Flex as="nav" justify="space-between" width="100%">
            <HStack spacing={8} pt={1} pb={1} pr={2} width="100%">
              <Flex direction="row" alignItems="center">
                <Box rel="home" pl={{ md: 2 }}>
                  <VisuallyHidden>{'Reflex'}</VisuallyHidden>
                  <Link href={OPS_HOME} passHref>
                    <LogoSpecial />
                  </Link>
                </Box>
                {environment && (
                  <Flex ml={2}>
                    <Text as="span" fontWeight="semi" color="main.white">
                      {environment}
                    </Text>
                  </Flex>
                )}
              </Flex>
              <HStack
                spacing={{ base: 2, md: 4 }}
                display={{ base: 'none', sm: 'flex' }}
              >
                <NavItem label="Ops" href={OPS_MAIN} isActive={false} />
                {navOptions
                  .filter(o => !o.desktopHidden)
                  .map(option => (
                    <NavItem
                      key={option.label}
                      label={option.label}
                      href={option?.href}
                      isActive={false}
                    />
                  ))}
              </HStack>
              <Flex flexGrow={1} flexDirection="row" justifyContent="flex-end">
                <NotificationPopover />
                <IconButton
                  variant="transparent"
                  aria-label="Menu"
                  color="main.white"
                  icon={<Icon name={ICON_NAMES.menu} h={6} w={6} />}
                  onClick={openMenuDrawer}
                />
              </Flex>
            </HStack>
          </Flex>
        </Box>
      </Box>
      <NavDrawer />
    </>
  );
};
