import { applyContext, fetchObject } from 'helpers';
import { ReflexResponse } from 'types';
import { FiltersQueries } from 'helpers/src/graphql/queries';
import { GQL } from 'types/src/routes';

export interface fetchFiltersProps {
  marketIsActive?: boolean;
}

export const fetchFilters = async (
  data: fetchFiltersProps,
  query = FiltersQueries.default,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, 'FILTERS_QUERY'),
    query,
    data,
    returnKey: 'filterOptions',
  });
