import { _PAGING_PARAMS, _PAGING_VARIABLES } from '../_paging';
import { _LOCATION_RETAILER } from '../location';
import {
  _PERMISSION_USERS_CORE,
  _USERS_PARAMS,
  _USERS_VARIABLES,
} from './user.helpers';

export const USER_ME_HAS_OUTSTANDING_BUDGET_APPROVALS = `
  query usersQuery {
    me {
      uuid
      hasOutstandingBudgetApprovals
    }
  }
`;

export const USER_PROFILE_FOR_RETAILER = `
  query USER_PROFILE_FOR_RETAILER (${_USERS_PARAMS}) {
    users (${_USERS_VARIABLES}) {
      firstName
      lastName
      preferredName
      tagline
      uuid
      profileImg
      favoritedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      approvedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      blockedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      experience {
        id
        retailer {
          name
          logoImage
        }
        name
        location
        start
        end
        duration
      }
      aboutMe
      hoursThisWeek
      totalShiftsCompleted
      lastShiftDate
      ratingAvg
      ratingCount
      allBrands {
        name
        logoImage
      }
      platformExperienceBrands {
        name
        logoImage
      }
      workerEndorsements {
        name
        count
        priority
        icon
      }
      workerGrowthAreas {
        name
        count
        priority
        icon
      }

    }
  }
`;

export const WORKER_PROFILE_FOR_RETAILER_QUERY = `
  query WORKERS_QUERY(
    $uuid: String
    $locations: [String]
    ${_PAGING_PARAMS}
  ) {
    users(
      uuid: $uuid
      locations: $locations
      ${_PAGING_VARIABLES}
    ) {
      uuid
      firstName
      lastName
      preferredName
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      blockedBy {
        uuid
      }
      lastShiftAtLocations {
        start
        retailerLocation {
          uuid
          name
          timezone
        }
        pendingReviews {
          id
          uuid
          reviewType
        }
      }
    }
  }
`;

export const WORKERS_QUERY = `
  query WORKERS_QUERY(
    $uuid: String
    $uuids: [String]
    $organization: String
    $locations: [String]
    $hasWorked: Boolean
    $hasLocationRelation: Boolean
    $favoriteAt: [String]
    $blockedAt: [String]
    $excludeBlockedAt: [String]
    $workedAtLocationsSince: Date
    ${_PAGING_PARAMS}
  ) {
    users(
      uuid: $uuid
      uuids: $uuids
      organization: $organization
      locations: $locations
      hasWorked: $hasWorked
      hasLocationRelation: $hasLocationRelation
      favoriteAt: $favoriteAt
      blockedAt: $blockedAt
      excludeBlockedAt: $excludeBlockedAt
      workedAtLocationsSince: $workedAtLocationsSince
      ${_PAGING_VARIABLES}
    ) {
      uuid
      firstName
      lastName
      isRetail
      preferredName
      profileImg
      eritId
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      blockedBy {
        uuid
      }
      totalShiftsCompleted
      lastShiftDate
      lastDateWorkedAtLocation
      relationLocations {
        uuid
      }
      lastShiftAtLocation {
        start
        retailerLocation {
          timezone
          uuid
          name
          billingName
          retailer {
            name
          }
        }
        pendingReviews {
          id
          uuid
          reviewType
        }
      }
      lastShiftAtLocationsWithoutRelation {
        start
        end
        retailerLocation {
          uuid
          timezone
          name
          billingName
          retailer {
            name
          }
        }
        pendingReviews {
          id
          uuid
          reviewType
        }
      }
      totalShiftsAtLocation
    }
  }
`;

export const RETAILER_SELECT_WORKERS_QUERY = `
  query RETAILER_SELECT_WORKERS_QUERY(
    $blockedAt: [String]
    $excludeBlockedAt: [String]
    $favoriteAt: [String]
    $hasLocationRelation: Boolean
    $hasWorked: Boolean
    $locations: [String]
    ${_PAGING_PARAMS}
  ) {
    users(
      blockedAt: $blockedAt
      excludeBlockedAt: $excludeBlockedAt
      favoriteAt: $favoriteAt
      hasLocationRelation: $hasLocationRelation,
      hasWorked: $hasWorked
      locations: $locations 
      ${_PAGING_VARIABLES}
    ) {
      uuid
      firstName
      lastName
      preferredName
      profileImg
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      lastShiftDate
      lastDateWorkedAtLocation
      totalShiftsAtLocation
      lastShiftAtLocation {
        start
        retailerLocation {
          timezone
        }
      }
    }
  }
`;

export const RETAILER_PERMISSION_SETTINGS_USERS = `
  query usersQuery (
    ${_USERS_PARAMS}
  ) {
    users (
      ${_USERS_VARIABLES}
    ) {
      ${_PERMISSION_USERS_CORE}
      organization {
        uuid
        name
      }
      locationsWithAccess {
        uuid
        name
        billingName
        ${_LOCATION_RETAILER}
      }
    }
  }
`;
