import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import {
  BoxProps,
  TabProps as ChakraTabProps,
  Tabs as ChakraTabs,
  TabsProps as ChakraTabsProps,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Icon } from 'icons';

interface TabsProps {
  items: Array<{
    title: string;
    titleProps?: ChakraTabProps;
    name?: string;
    panel: ReactElement;
  }>;
  tabProps?: Partial<ChakraTabsProps>;
  titlesContainerProps?: BoxProps;
  defaultTitleProps?: ChakraTabProps;
  panelsContainerProps?: BoxProps;
  onTabChange?: (tabName: string) => void;
  useQueryParams?: boolean;
}

export const Tabs: FC<TabsProps> = ({
  items,
  tabProps,
  titlesContainerProps,
  panelsContainerProps,
  defaultTitleProps,
  onTabChange,
  useQueryParams = true,
}) => {
  const containerRef = useRef<HTMLDivElement | null>();
  const tabRefs = useRef<Array<HTMLButtonElement | null>>([]);
  const router = useRouter();
  const { pathname, query } = router;
  const [tabIndex, setTabIndex] = useState(0);
  const tabNames = items.map(item => (item.name || item.title).toLowerCase());
  const [containerWidth, setContainerWidth] = useState(0);
  const [tabWidths, setTabWidths] = useState(0);
  const {
    isOpen: isPopoverOpen,
    onToggle: onPopoverToggle,
    onClose: onPopoverClose,
  } = useDisclosure();

  const handleTabsChange = index => {
    if (useQueryParams) {
      router.replace(
        {
          pathname,
          query: { ...query, tab: tabNames[index] },
        },
        null,
        { shallow: true },
      );
    } else {
      setTabIndex(index);
    }
    !!onTabChange && onTabChange(tabNames[index]);
  };

  const changeTabByQuery = useCallback(
    tab => {
      const currentTabNames = items.map(item =>
        item.name ? item.name.toLowerCase() : item.title.toLowerCase(),
      );
      if (!currentTabNames.includes(tab)) return;
      setTabIndex(currentTabNames.indexOf(tab));
    },
    [items],
  );

  useEffect(() => {
    if (!query || !query.tab || !useQueryParams) return;
    changeTabByQuery(query.tab);
  }, [query, changeTabByQuery, useQueryParams]);

  useEffect(() => {
    tabRefs.current = tabRefs.current.slice(0, items.length);
  }, [items]);

  useEffect(() => {
    const totalWidth = tabRefs?.current?.reduce((prev, curr) => {
      const width = curr.offsetWidth;
      return prev + width;
    }, 0);
    setTabWidths(totalWidth);
  }, [tabRefs]);

  useEffect(() => {
    setContainerWidth(containerRef?.current?.offsetWidth);
  }, [containerRef]);

  return (
    <ChakraTabs
      index={tabIndex}
      flexGrow={1}
      display="flex"
      flexDirection="column"
      onChange={handleTabsChange}
      ref={containerRef}
      {...tabProps}
    >
      <Flex
        mt={4}
        px={4}
        width="100%"
        borderStyle="solid"
        borderBottomWidth="1px"
        borderColor="main.medium"
        {...titlesContainerProps}
      >
        <TabList
          border="0"
          position="relative"
          zIndex={1}
          width="fit-content"
          mb="-2px"
          px={0}
          py={0}
          mr={tabWidths >= containerWidth ? 8 : 0}
          overflowY="hidden"
          sx={{
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {items.map((item, index) => (
            <Tab
              key={item.title}
              color="text.disabled"
              borderBottomWidth="1px"
              mb="1px"
              py={2}
              fontSize="sm"
              fontWeight="medium"
              _selected={{
                color: 'oz.primary',
                borderColor: 'blackAlpha.600',
                shadow: 'none',
              }}
              _active={{
                backgroundColor: 'blackAlpha.50',
              }}
              ref={el => (tabRefs.current[index] = el)}
              {...defaultTitleProps}
              {...item.titleProps}
            >
              {item.title}
            </Tab>
          ))}
        </TabList>
        {tabWidths >= containerWidth && (
          <Popover
            closeOnBlur={true}
            placement="bottom-end"
            isOpen={isPopoverOpen}
            onClose={onPopoverClose}
          >
            <PopoverTrigger>
              <IconButton
                aria-label="more tabs"
                variant="transparent"
                size="sm"
                background="main.white"
                pt={1}
                _active={{ background: 'main.white' }}
                _hover={{ background: 'main.white' }}
                _focus={{ background: 'main.white' }}
                icon={<Icon name="dotshorizontal" w={5} h={5} />}
                onClick={onPopoverToggle}
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent borderColor="main.medium">
                <PopoverBody p={0}>
                  {items.map((item, index) => (
                    <Flex
                      key={`${item.title}-menu`}
                      _disabled={{
                        cursor: 'not-allowed',
                        backgroundColor: 'blackAlpha.300',
                      }}
                      _hover={{
                        backgroundColor: 'blackAlpha.100',
                      }}
                      align="center"
                      color={
                        tabIndex === index ? 'main.navy' : 'text.secondary'
                      }
                      backgroundColor={
                        tabIndex === index ? 'blue.50' : 'transparent'
                      }
                      cursor="pointer"
                      px={3}
                      py={2}
                      width="100%"
                      as="button"
                      onClick={e => {
                        e.preventDefault();
                        handleTabsChange(index);
                        onPopoverClose();
                      }}
                    >
                      <Text ml={2} fontWeight="medium">
                        {item.title}
                      </Text>
                    </Flex>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        )}
      </Flex>
      <Flex flexGrow={1} {...panelsContainerProps}>
        <TabPanels display="flex" flexDirection="column" flexGrow={1}>
          {items.map(item => (
            <TabPanel
              key={item.title}
              flexGrow={1}
              py={0}
              px={0}
              display="flex"
              flexDirection="column"
            >
              {item.panel}
            </TabPanel>
          ))}
        </TabPanels>
      </Flex>
    </ChakraTabs>
  );
};
