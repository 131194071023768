import { format } from 'date-fns';
import { budgetPeriod, locationBudget } from 'types';
import { limitToMaxDecimalPlaces } from '../data';
import { formatNumWithCommas } from '../strings';

export const BUDGET_CLOSE_THRESHOLD = 0.8;

// isolates where we are handling rounding
// in case we need to change the strategy
export const roundBudgetValue = (
  value: number = 0,
  decimals: number = 0,
): number => {
  if (!value) return 0;
  // unless explicit, always round up to nearest whole number
  if (decimals === 0) return Math.ceil(value);
  return limitToMaxDecimalPlaces(value, decimals);
};

// isolates where we format the number into string with commas
// in case we need to change the strategy
// WILL ROUND DOWN
export const formatBudgetValue = (value: number, decimals = 0): string => {
  return formatNumWithCommas(value, decimals);
};

export const getBudgetFromPeriodStartEnd = (
  budgets: locationBudget[],
  start: string,
  end: string,
): locationBudget => {
  if (!budgets?.length || !start || !end) return {} as locationBudget;
  if (budgets?.length === 1) return budgets[0];
  return budgets.find(budget => budget.start >= start && budget.end <= end);
};

export const getBudgetPeriodFromDate = (
  date: Date,
  budgetPeriods?: budgetPeriod[],
) => {
  if (!date || !budgetPeriods?.length) return {} as budgetPeriod;
  return budgetPeriods.find(
    period => date >= new Date(period.start) && date <= new Date(period.end),
  );
};

export const getFormattedBudgetPeriodDates = (budget: locationBudget) => {
  return {
    formattedStart: budget?.start
      ? format(new Date(budget?.start), 'MM/dd')
      : '',
    formattedEnd: budget?.end ? format(new Date(budget?.end), 'MM/dd') : '',
  };
};
