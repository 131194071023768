import { FC } from 'react';
import { useRouter } from 'next/router';
import { Button, Flex } from '@chakra-ui/react';
import { OPS_SHIFT } from 'types';
import { useOverbookStore } from 'utils/stores';
import { useViewStore } from 'utils/stores/ViewStore';
import { GraphResponse } from 'components/common/GraphResponse';
import { getGraphResponseProps, responseHasIssues } from './overbook.helpers';

export const OverbookFinale: FC = () => {
  const router = useRouter();
  const [newShift, meta, resetStore] = useOverbookStore(s => [
    s.newShift,
    s.meta,
    s.resetStore,
  ]);
  const [closeModal] = useViewStore(s => [s.closeModal]);
  const getShiftRoute = shift =>
    `${OPS_SHIFT.replace(/\[uuid\]/i, shift?.uuid)}`;
  const hasIssues = responseHasIssues(meta?.graphResponse);

  const handleShiftButtonClick = () => {
    closeModal();
    resetStore();
    const route = getShiftRoute(newShift);
    router.push(route);
  };

  return (
    <Flex minHeight={100} flexDirection="column" justifyContent="center">
      <GraphResponse
        {...getGraphResponseProps(meta?.graphResponse)}
        containerProps={{ minHeight: 'unset', mt: 6 }}
      />
      <Button
        mt={6}
        mb={2}
        size="md"
        variant="outline"
        color={hasIssues ? 'main.red' : 'main.green'}
        _hover={{ backgroundColor: hasIssues ? 'red.50' : 'green.50' }}
        maxWidth={200}
        width="100%"
        alignSelf="center"
        onClick={handleShiftButtonClick}
      >
        {'View Flex'}
      </Button>
    </Flex>
  );
};
