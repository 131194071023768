import { FC } from 'react';
import { Button, ButtonProps, Flex, IconProps } from '@chakra-ui/react';
import { Icon } from 'icons';
import { ButtonVariants } from 'styles/config/button.config';
import { MotionBox } from './MotionBox';

const successColor = {
  primary: 'main.white',
  greenish: 'green.500',
  lightGreen: 'green.500',
  light: 'oz.secondary',
  dark: 'main.white',
  transparent: 'oz.secondary',
  danger: 'main.white',
  redish: 'red.500',
};

interface BetterButtonProps extends ButtonProps {
  isProcessing?: boolean;
  isDisabled?: boolean;
  isSuccess?: boolean;
  onClick?: (e) => void | Promise<void>;
  label: string;
  iconProps?: IconProps;
  variant?: keyof typeof ButtonVariants;
}

export const BetterButton: FC<BetterButtonProps> = ({
  isProcessing = false,
  isDisabled = false,
  isSuccess,
  onClick,
  label,
  iconProps,
  variant = 'primary',
  ...buttonProps
}) => {
  return (
    <Button
      isLoading={isProcessing}
      isDisabled={isDisabled}
      onClick={isSuccess || isProcessing ? () => null : e => onClick(e)}
      backgroundColor={
        variant ? undefined : isDisabled && !isSuccess && 'main.medium'
      }
      color={
        variant
          ? undefined
          : isDisabled && !isSuccess
            ? 'gray.400'
            : 'main.white'
      }
      _hover={{
        backgroundColor: variant
          ? undefined
          : isDisabled && !isSuccess && 'main.medium',
      }}
      _active={{
        backgroundColor: variant
          ? undefined
          : isDisabled && !isSuccess && 'main.medium',
      }}
      opacity={variant ? undefined : isDisabled && !isSuccess && 0.3}
      variant={variant}
      {...buttonProps}
    >
      {isSuccess && (
        <MotionBox
          initial={{ opacity: 0, marginTop: -12 }}
          animate={{ opacity: 1, marginTop: 0 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.25 }}
        >
          <Flex
            h={6}
            w={6}
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              name="check"
              h={6}
              w={6}
              color={successColor[variant] ?? 'main.white'}
              {...iconProps}
            />
          </Flex>
        </MotionBox>
      )}
      {isSuccess ? null : label}
    </Button>
  );
};
