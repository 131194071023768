import { FC } from 'react';
import { Button, Flex, FlexProps } from '@chakra-ui/react';
import {
  fetchLocations,
  fetchLocationsProps,
  getLocationNameForDisplay,
  Queries,
} from 'helpers';
import { ICON_NAMES } from 'icons';
import { DataKeys, location } from 'types';
import { TextAndIcon } from 'ui';
import { SelectList, SelectListProps } from 'components/common/SelectList';

type SelectLocationItemProps = location &
  SelectListProps<location, fetchLocationsProps> & {
    onSelect?: (location: location) => void;
    selectButtonText?: string;
    containerProps?: FlexProps;
  };

const SelectLocationItem: FC<SelectLocationItemProps> = ({
  onSelect,
  selectButtonText = 'Select',
  containerProps,
  selectedValues, // eslint-disable-line unused-imports/no-unused-vars
  ...location
}) => (
  <Flex
    flexDirection="column"
    flexWrap="wrap"
    py={2}
    px={{ base: 4, md: 6 }}
    userSelect="none"
    position="relative"
    {...containerProps}
  >
    <Flex mb={1}>
      <TextAndIcon
        text={getLocationNameForDisplay(location)}
        textProps={{
          fontWeight: 'medium',
          ml: 2,
          lineHeight: 1,
        }}
        icon={ICON_NAMES.storefrontalt}
        containerProps={{ flexGrow: 1 }}
      />
      <Button
        size="sm"
        variant="transparent"
        onClick={() => {
          !!onSelect && onSelect(location);
        }}
      >
        {selectButtonText}
      </Button>
    </Flex>
  </Flex>
);

export const SelectLocationsList: FC<
  SelectListProps<location, fetchLocationsProps>
> = ({ onSelect, selectButtonText = 'Select', ...props }) => {
  return (
    <SelectList<location, fetchLocationsProps>
      dataKey={DataKeys.LOCATIONS}
      query={Queries.RETAILER_LOCATIONS_FOR_SELECT}
      fetchFunction={fetchLocations}
      itemComponent={
        <SelectLocationItem
          onSelect={onSelect}
          selectButtonText={selectButtonText}
        />
      }
      onSelect={onSelect}
      {...props}
    />
  );
};
