export enum JobStatus {
  SENT = 'SENT',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  RETRY = 'RETRY',
}

export enum JobType {
  FAST_FORWARD_PLAYBOOK = 'FAST_FORWARD_PLAYBOOK',
  MESSAGE_USERS = 'MESSAGE_USERS',
  CANCEL_WSOS = 'CANCEL_WSOS',
  DELETE_WSOS = 'DELETE_WSOS',
  RESTART_PLAYBOOK = 'RESTART_PLAYBOOK',
  PROCESS_PLAYBOOK_STEP = 'PROCESS_PLAYBOOK_STEP',
  PLAYBOOK_PAUSE_TOGGLED = 'PLAYBOOK_PAUSE_TOGGLED',
  CREATE_PLAYBOOK = 'CREATE_PLAYBOOK',
}

export type Job = {
  jobType: JobType;
  jobKey: string;
  taskId?: string;
  status: JobStatus;
};

export type JobCopy = Partial<
  Record<JobType, Partial<Record<JobStatus, string>>>
>;

// FUTURE: consider renaming to something more generic like 'EmittedEvent'
