import { ActionMap } from 'types';
import { CalendarDate, CalendarValues } from 'ui';
import { MarketSelectOption } from 'types/src/form';
import { apiShiftDetail, shift } from 'types/src/shift';
import { graphUserLight, worker } from 'types/src/user';
import {
  convertArrayToFilterOptions,
  convertEnumToFilterOptions,
  getFilterObjectByValue,
  getFilterObjectByValues,
} from 'utils/helpers/filters';
import {
  FilterOption,
  ShiftPaperworkFilters,
  ShiftStatusFilters,
  TimingFilters,
} from 'utils/types/filters';
import { formState, formStateDefaults } from 'utils/types/form';

export interface ShiftsStateInterface {
  activeShift: shift;
  formState: formState;
  selectedShifts?: {
    [uuid: string]: shift;
  };
  selectedWorkers?: worker;
}

export const initialState = {
  activeShift: {},
  formState: formStateDefaults,
  selectedShifts: {},
  selectedWorkers: {},
};

export enum ShiftsActions {
  SELECT_SHIFT = 'SELECT_SHIFT',
  REMOVE_SHIFT = 'REMOVE_SELECTED_SHIFT',
  REMOVE_ALL_SHIFTS = 'REMOVE_ALL_SELECTED_SHIFTS',
  ADD_WORKER = 'ADD_SHIFT_WORKER',
  ADD_WORKERS = 'ADD_SHIFT_WORKERS',
  REMOVE_ALL_WORKERS = 'REMOVE_SHIFT_WORKERS',
  REMOVE_WORKER = 'REMOVE_SHIFT_WORKER',
  RESET_SHIFTS_CONTEXT = 'RESET_SHIFTS_CONTEXT',
  UPDATE_ACTIVE_SHIFT = 'UPDATE_ACTIVE_SHIFT',
  UPDATE_FORM_STATE = 'UPDATE_SHIFT_FORM_STATE',
  UPDATE_FORM_PROCESSING = 'UPDATE_SHIFT_FORM_IS_PROCESSING',
  UPDATE_FORM_SUCCESS = 'UPDATE_SHIFT_FORM_IS_SUCCESS',
  UPDATE_FORM_ERRORS = 'UPDATE_SHIFT_FORM_ERRORS',
}

type ShiftsPayload = {
  [ShiftsActions.SELECT_SHIFT]: Partial<apiShiftDetail>;
  [ShiftsActions.REMOVE_SHIFT]: { uuid: string };
  [ShiftsActions.REMOVE_ALL_SHIFTS]: null;
  [ShiftsActions.ADD_WORKER]: Partial<graphUserLight>;
  [ShiftsActions.ADD_WORKERS]: Array<Partial<graphUserLight>>;
  [ShiftsActions.REMOVE_ALL_WORKERS]: null;
  [ShiftsActions.REMOVE_WORKER]: { uuid: string };
  [ShiftsActions.RESET_SHIFTS_CONTEXT]: null;
  [ShiftsActions.UPDATE_ACTIVE_SHIFT]: shift;
  [ShiftsActions.UPDATE_FORM_PROCESSING]: boolean;
  [ShiftsActions.UPDATE_FORM_SUCCESS]: boolean;
  [ShiftsActions.UPDATE_FORM_ERRORS]: Array<string>;
};

type ShiftsActionTypes =
  ActionMap<ShiftsPayload>[keyof ActionMap<ShiftsPayload>];

export const shiftsReducer = (draft, action: ShiftsActionTypes) => {
  const { type } = action;
  switch (type) {
    case ShiftsActions.SELECT_SHIFT:
      draft.selectedShifts[action?.payload.uuid] = action?.payload;
      break;
    case ShiftsActions.REMOVE_SHIFT:
      delete draft.selectedShifts[action?.payload.uuid];
      break;
    case ShiftsActions.REMOVE_ALL_SHIFTS:
      draft.selectedShifts = {};
      break;
    case ShiftsActions.ADD_WORKER:
      draft.selectedWorkers[action?.payload.uuid] = action?.payload;
      break;
    case ShiftsActions.ADD_WORKERS:
      action?.payload.forEach(worker => {
        draft.selectedWorkers[worker.uuid] = worker;
      });
      break;
    case ShiftsActions.REMOVE_WORKER:
      delete draft.selectedWorkers[action?.payload.uuid];
      break;
    case ShiftsActions.REMOVE_ALL_WORKERS:
      draft.selectedWorkers = {};
      break;
    case ShiftsActions.RESET_SHIFTS_CONTEXT:
      draft = initialState;
      break;
    case ShiftsActions.UPDATE_ACTIVE_SHIFT:
      draft.activeShift = action?.payload;
      break;
    case ShiftsActions.UPDATE_FORM_PROCESSING:
      draft.formState.isProcessing = action?.payload;
      break;
    case ShiftsActions.UPDATE_FORM_SUCCESS:
      draft.formState.isSuccess = action?.payload;
      break;
    case ShiftsActions.UPDATE_FORM_ERRORS:
      draft.formState.errors = action?.payload;
      break;
    default:
      return draft;
  }
  return draft;
};
