import { cloneElement, FC, ReactElement, ReactNode, useRef } from 'react';
import {
  BoxProps,
  Drawer as ChakraDrawer,
  DrawerProps as ChakraDrawerProps,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';

export interface DrawerProps {
  trigger: ReactElement;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  drawerPlacement?: ChakraDrawerProps['placement'];
  containerProps?: BoxProps;
}

export const Drawer: FC<DrawerProps> = ({
  trigger,
  header,
  body,
  footer,
  drawerPlacement = 'left',
  containerProps,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const triggerRef = useRef();

  const clonedTrigger = cloneElement(trigger, {
    onClick: e => {
      // Call the click handler of the original trigger argument alongside
      // the onOpen function to ensure that we aren't overriding any handlers
      trigger.props.onClick && trigger.props.onClick(e);
      onOpen();
    },
    ref: triggerRef,
  });

  return (
    <>
      {clonedTrigger}
      <ChakraDrawer
        isOpen={isOpen}
        placement={drawerPlacement}
        onClose={onClose}
        finalFocusRef={triggerRef}
      >
        <DrawerOverlay />
        <DrawerContent {...containerProps}>
          <DrawerCloseButton />
          <DrawerHeader>{header}</DrawerHeader>
          <DrawerBody>{body}</DrawerBody>
          <DrawerFooter justifyContent="flex-start">{footer}</DrawerFooter>
        </DrawerContent>
      </ChakraDrawer>
    </>
  );
};
