import { FC, useState } from 'react';
import { useRouter } from 'next/router';
import { Flex, Text } from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';
import { OPS_SHIFTS } from 'types';
import { BetterButton } from 'ui';
import { ActionState, useOverbookStore, useViewStore } from 'utils/stores';
import { useUpdateFiltersForMove } from './overbook.helpers';
import { WorkerMeta } from './WorkerMeta';

enum Options {
  'NEW' = 'NEW',
  'EXISTING' = 'EXISTING',
}

interface OptionCardProps {
  text: string;
  icon: string;
  value: Options;
  isActive?: boolean;
  onClick: (value: Options) => void;
}

export const OptionCard: FC<OptionCardProps> = ({
  text,
  icon,
  isActive = false,
  value,
  onClick,
}) => {
  const handleClick = () => {
    onClick(value);
  };

  return (
    <Flex
      alignItems="center"
      backgroundColor={isActive ? 'green.100' : 'main.white'}
      borderRadius="lg"
      borderWidth={2}
      borderStyle="solid"
      borderColor={isActive ? 'green.200' : 'blackAlpha.100'}
      mb={3}
      cursor="pointer"
      as="button"
      onClick={handleClick}
    >
      <Flex w={12} h={12} position="relative" zIndex={2}>
        <Icon
          name={icon}
          position="relative"
          zIndex={2}
          h={6}
          w={6}
          mt={3}
          ml={3}
          color={isActive ? 'green.500' : 'blackAlpha.500'}
        />
      </Flex>
      <Text
        fontSize={{ base: 'md', sm: 'xl' }}
        fontWeight="medium"
        color={isActive ? 'green.500' : 'text.secondary'}
      >
        {text}
      </Text>
    </Flex>
  );
};

export const OverbookChoice: FC = () => {
  const router = useRouter();
  const [activeOption, setActiveOption] = useState(Options.NEW);
  const [closeModal] = useViewStore(s => [s.closeModal]);
  const [workershift, updateMeta, shift] = useOverbookStore(s => [
    s.workershift,
    s.updateMeta,
    s.shift,
  ]);
  const [isProcessing, setIsProcessing] = useState(false);

  const { updateFilters } = useUpdateFiltersForMove(shift);

  const handleUpdateActiveOption = (value: Options) => {
    setActiveOption(value);
  };

  const handleButtonClick = () => {
    setIsProcessing(true);
    if (activeOption === Options.NEW) {
      updateMeta('actionState', ActionState.FORM);
    } else {
      updateFilters();
      router.push({ pathname: OPS_SHIFTS }, undefined, { shallow: true });
      updateMeta('actionState', ActionState.SELECT);
      setTimeout(() => {
        closeModal();
      }, 500);
    }
  };

  return (
    <Flex flexDirection="column">
      <Text variant="header-xl" mt={2} mb={3}>
        {'Move Overbooking'}
      </Text>
      <WorkerMeta {...workershift?.worker} />
      <Flex direction="column" alignSelf="center" width="100%" mt={4}>
        <OptionCard
          icon={ICON_NAMES.sparkleplus}
          text="Create New Flex"
          value={Options.NEW}
          onClick={handleUpdateActiveOption}
          isActive={activeOption === Options.NEW}
        />
        <OptionCard
          icon={ICON_NAMES.storefrontalt}
          text="Choose Existing Flex"
          value={Options.EXISTING}
          onClick={handleUpdateActiveOption}
          isActive={activeOption === Options.EXISTING}
        />
      </Flex>
      <BetterButton
        label="Continue"
        variant="light"
        onClick={handleButtonClick}
        size="lg"
        width="100%"
        maxWidth={180}
        alignSelf="center"
        mt={6}
        iconProps={{ color: ' main.green' }}
        isProcessing={isProcessing}
      />
    </Flex>
  );
};
