import { SelectOption } from 'types';
import * as yup from 'yup';
import { convertEnumToSelectOptions } from 'utils/helpers/form';

export interface OverbookShiftFormData {
  date: string;
  startTime: SelectOption;
  endTime: SelectOption;
  roles: SelectOption[];
  reason: SelectOption;
  bonus: string;
}

export const OverbookShiftFormDefaults: OverbookShiftFormData = {
  date: '',
  startTime: null,
  endTime: null,
  roles: [],
  reason: null,
  bonus: '0',
};

export const OverbookShiftFormValidationSchema = yup.object().shape({
  date: yup.string().required('Date Required'),
  startTime: yup.object().nullable().required('Start Required'),
  endTime: yup.object().nullable().required('End Required'),
  roles: yup.array().min(1, 'Role Required').required('Role Required'),
  reason: yup.object().nullable().required('Reason Required'),
});

enum OverbookMoveReasons {
  UPSOLD = 'Upsold',
  COMPED = 'Comped (100% discount set)',
}

export const overbookReasons = convertEnumToSelectOptions(OverbookMoveReasons);
