import {
  EventDetailState,
  payment,
  PaymentType,
  shift,
  user,
  workershift,
  WorkerShiftEventsEnum,
} from 'types';
import { limitToMaxDecimalPlaces } from '../data';
import { isNullDate } from '../dates';
import {
  createWorkershiftEventMaps,
  WorkerShiftEventsMap,
} from './workerShift';

export const getCoreShiftInfo = (shift?: shift) => {
  const { retailerLocation: location } = shift || {};
  const { retailer } = location || {};
  return {
    locationFullName: `${retailer?.name} - ${location?.name}`,
  };
};

export const getAssignedWorker = (shift?: shift) => {
  const { assignedWorkers, overbookLimit, overbookedWorkers } = shift || {};
  const assignedWorkerCount = assignedWorkers?.length;
  const hasAssignedWorker = assignedWorkerCount > 0;
  const hasOverbookedWorkers = overbookedWorkers?.length > 0;
  const assignedWorker = assignedWorkers?.[0];
  return {
    overbookLimit,
    assignedWorkerCount,
    hasAssignedWorker,
    assignedWorker,
    assignedWorkerIsFavorite: assignedWorker?.favoritedBy
      ?.map(location => location?.uuid)
      .includes(shift?.retailerLocation?.uuid),
    assignedWorkerIsApproved: assignedWorker?.approvedBy
      ?.map(location => location?.uuid)
      .includes(shift?.retailerLocation?.uuid),
    assignedWorkerIsBlocked: assignedWorker?.blockedBy
      ?.map(location => location?.uuid)
      .includes(shift?.retailerLocation?.uuid),
    newToStore: !assignedWorker?.hasWorkedAtLocation,
    // ? ^ might need to make this check wso is complete and count is 1 then true
    hasOverbookedWorkers,
    hasAssignedOrOverbookedWorkers: hasAssignedWorker || hasOverbookedWorkers,
  };
};

export const getAssignedAndOverbookedWorkers = (shift?: shift) => {
  if (!shift) return [];
  const { assignedWorkers, overbookedWorkers } = shift || {};
  return [...(assignedWorkers || []), ...(overbookedWorkers || [])];
};

export const isViewingWorkerAssignedOrOverbooked = (
  shift?: shift,
  workerUuid?: string,
): boolean => {
  return getAssignedAndOverbookedWorkers(shift)
    ?.map(w => w?.uuid)
    .includes(workerUuid);
};

export const getUserFromAssignedAndOverbooked = (
  shift?: shift,
  workerUuid?: string,
): user => {
  if (!shift || !workerUuid) return {};
  const allWorkers = getAssignedAndOverbookedWorkers(shift);
  return allWorkers.find(w => w?.uuid === workerUuid) || {};
};

export const convertShiftWorkerToWorkerShift = (sw: user): workershift => {
  const { workerShift, ...worker } = sw;
  return {
    ...workerShift,
    worker,
  };
};

export const getShiftBasePayment = (shift: shift) => {
  return shift?.payments?.filter(p => p.type === PaymentType.BASE)[0];
};

export const getShiftBonusPayment = (shift: shift) => {
  return shift?.payments?.filter(
    p => p.type === PaymentType.FLEX_FULFILLMENT_INCENTIVE,
  )[0];
};

export const calculateBasePayment = (shift: shift): number => {
  const payments = shift?.payments || [];
  if (payments.length === 0) return 0;
  const basePayment = payments.find(({ type }) => type === PaymentType.BASE);

  return basePayment
    ? Number(basePayment?.quantity) * Number(basePayment?.rate)
    : 0;
};

export const calculateBonusPayment = (shift: shift): number => {
  const payments = shift?.payments || [];
  if (payments.length === 0) return 0;
  return payments.reduce(
    (sum, { quantity, rate, type }) =>
      type && type !== PaymentType.BASE
        ? sum + Number(quantity) * Number(rate)
        : sum,
    0,
  );
};

export const calculateTotalEstimatedPayment = (shift: shift): number => {
  return calculateBasePayment(shift) + calculateBonusPayment(shift);
};

export const formatPayment = (payment: number): string =>
  `$${payment.toFixed(2)}`;

export const getFormattedPaymentValues = (payment: payment) => {
  const hours = parseFloat(payment?.quantity) || 0;
  const rate = parseFloat(payment?.rate);
  const base = rate * hours;
  const formattedRate = formatPayment(rate);
  const formattedBase = formatPayment(base);
  return {
    ...payment,
    hours,
    rate,
    formattedRate,
    formattedBase,
    base,
  };
};

export const getBreak = (shift: shift) => {
  const shiftDuration = shift?.duration * 60; // duration is in hours
  const breakRule = shift?.retailerLocation?.compliance?.breakRules[0] || {};
  const minShiftHoursToRequireBreak = breakRule?.shiftMinutesToRequireBreak;
  const isBreakPaid = breakRule?.paid;
  const breakLength = breakRule?.breakLength;
  const hasBreak = shiftDuration > minShiftHoursToRequireBreak;

  return {
    hasBreak,
    breakLength,
    isBreakPaid,
  };
};

export const getPendingReviews = (shift: shift) => {
  const hasPendingReview = shift?.pendingReviews?.length > 0;
  return {
    hasPendingReview,
  };
};

const getEventProblems = (
  workerShiftEventsMap: Partial<WorkerShiftEventsMap>,
): string[] => {
  return Object.keys(workerShiftEventsMap)
    .map(key => {
      if (
        [
          EventDetailState.ISSUE_MISSING,
          EventDetailState.ISSUE_NEEDS_APPROVAL,
        ].includes(workerShiftEventsMap[key]?.state)
      ) {
        return key;
      }
    })
    .filter(x => !!x);
};

interface getWorkerShiftsEventsReturn {
  assignedWorker: user;
  shiftTimezone: string;
  workerShiftEventsMap: Partial<WorkerShiftEventsMap>;
  clockInTime: string;
  clockOutTime: string;
  clockInDate: Date;
  clockOutDate: Date;
  diffInMilliseconds: number;
  hours: number;
  acknowledgedBreak: any; // FUTURE: stronger type
  formattedClockInTime: string;
  formattedClockOutTime: string;
  wasLate: boolean;
  eventProblems: string[];
  hasClockedIn: boolean;
  hasClockedOut: boolean;
}

export const getWorkerShiftEvents = (
  shift: shift,
  shiftWorker: user = {},
): getWorkerShiftsEventsReturn => {
  const { assignedWorker: assignedWorkerFromHelper } = getAssignedWorker(shift);
  const assignedWorker =
    Object.keys(shiftWorker).length > 0
      ? shiftWorker
      : assignedWorkerFromHelper;
  const shiftTimezone = shift?.retailerLocation?.timezone;
  const workerShiftEventsMap = createWorkershiftEventMaps(
    assignedWorker?.workerShift?.workershiftEvents,
    shiftTimezone,
  );

  const clockInEvent = workerShiftEventsMap[WorkerShiftEventsEnum.CLOCK_IN];
  const clockInTime = clockInEvent?.timestamp;

  const clockOutEvent = workerShiftEventsMap[WorkerShiftEventsEnum.CLOCK_OUT];
  const clockOutTime = clockOutEvent?.timestamp;

  const clockInDate = new Date(clockInTime);
  const hasClockedIn = !isNullDate(clockInDate);
  const clockOutDate = new Date(clockOutTime);
  const hasClockedOut = !isNullDate(clockOutDate);

  const diffInMilliseconds = Math.abs(+clockInDate - +clockOutDate);
  const hours = limitToMaxDecimalPlaces(
    diffInMilliseconds / (1000 * 60 * 60),
    2,
  );

  const acknowledgedBreak =
    workerShiftEventsMap[WorkerShiftEventsEnum.BREAK_ACKNOWLEDGED];

  return {
    assignedWorker,
    shiftTimezone,
    workerShiftEventsMap,
    clockInTime,
    clockOutTime,
    clockInDate: clockInEvent?.dateStamp,
    clockOutDate: clockOutEvent?.dateStamp,
    diffInMilliseconds,
    hours,
    acknowledgedBreak,
    formattedClockInTime: clockInEvent?.formattedTimestamp,
    formattedClockOutTime: clockOutEvent?.formattedTimestamp,
    wasLate: clockInEvent?.wasLate || clockOutEvent?.wasLate,
    eventProblems: getEventProblems(workerShiftEventsMap),
    hasClockedIn,
    hasClockedOut,
  };
};

export const getWorkerShiftEventsForViewingWorker = (
  shift?: shift,
  workerUuid?: string,
): Partial<getWorkerShiftsEventsReturn> => {
  if (!shift || !workerUuid) return {};
  const viewingWorker = getUserFromAssignedAndOverbooked(shift, workerUuid);
  return getWorkerShiftEvents(shift, viewingWorker);
};

export const getAddressString = (shift?: shift): string => {
  const { address } = shift?.retailerLocation || {};
  if (!address || !Object.keys(address).length) return '';
  return `${address?.address1}, ${
    address?.address2 ? address?.address2 + ', ' : ''
  } ${address?.city}, ${address?.state} ${address?.zip}`;
};

export function isConfirmationPending(
  events: Partial<WorkerShiftEventsMap>,
): boolean {
  const currentTime = new Date();

  const isWithinTimeRange = (start: string, end: string): boolean => {
    const rangeStart = new Date(start);
    const rangeEnd = new Date(end);
    return currentTime >= rangeStart && currentTime <= rangeEnd;
  };

  const confirmation2 = events?.['CONFIRMATION_2'];
  if (
    confirmation2 &&
    isWithinTimeRange(
      confirmation2.expectedTimeRangeStart,
      confirmation2.expectedTimeRangeEnd,
    ) &&
    confirmation2.state !== 'COMPLETE'
  ) {
    return true;
  }

  const confirmation24 = events?.['CONFIRMATION_24'];
  if (
    confirmation24 &&
    isWithinTimeRange(
      confirmation24.expectedTimeRangeStart,
      confirmation24.expectedTimeRangeEnd,
    ) &&
    confirmation24.state !== 'COMPLETE'
  ) {
    return true;
  }

  return false;
}
