export const UPDATE_RELATION_MUTATION = `
  mutation RELATION_MUTATION(
    $locations: [String]
    $users: [String]
    $userStatus: String
    $locationStatus: String
    $note: String
    ) {
    updateUserRelation(
      locations: $locations
      users: $users
      userStatus: $userStatus
      locationStatus: $locationStatus
      note: $note
      ) {
        ok
        messages {
          code
        }
    }
  }
`;

export const UPDATE_LOCATION_MUTATION = `
  mutation UPDATE_LOCATION_MUTATION(
    $locations: [String]
    $name: String
    $market: String
    $isActive: Boolean
    $parking: String
    $phoneNumber: String
    $storeEmail: String
    $status: String
    $useV2FlexDetails: Boolean
    $billingName: String
  ) {
    updateLocation(
      locations: $locations
      name: $name
      market: $market
      isActive: $isActive
      parking: $parking
      phoneNumber: $phoneNumber
      storeEmail: $storeEmail
      status: $status
      useV2FlexDetails: $useV2FlexDetails
      billingName: $billingName
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const CREATE_LOCATION_MUTATION = `
  mutation CREATE_LOCATION_MUTATION(
    $name: String!
    $retailer: String!
    $terms: String!
    $compliance: Int!
    $address: AddressInput
    $market: Int
    $mapImg: String
    $mapHref: String
    $paymentRate: Float
    $phoneNumber: String
    $storeEmail: String
    $timezone: String
    $organization: String
    $status: String
  ){
    createLocation(
      name: $name
      retailer: $retailer
      terms: $terms
      compliance: $compliance
      address: $address
      market: $market
      mapImg: $mapImg
      mapHref: $mapHref
      paymentRate: $paymentRate
      phoneNumber: $phoneNumber
      storeEmail: $storeEmail
      timezone: $timezone
      organization: $organization
      status: $status
    ) {
      ok
      messages {
        code
      }
      uuid
    }
  }
`;
