import {
  add,
  differenceInMinutes,
  eachMinuteOfInterval,
  endOfDay,
  endOfMonth as endOfMonthFn,
  format,
  formatDistance,
  getHours,
  getMinutes,
  isAfter,
  isPast,
  isToday,
  isTomorrow,
  parse,
  startOfDay,
  startOfMonth as startOfMonthFn,
} from 'date-fns';
import { formatInTimeZone, toDate } from 'date-fns-tz';

interface CreateTimeIntervalOptionsProps {
  startHour: number;
  startMinute?: number;
  endHour: number;
  endMinute?: number;
  interval: number; // Minutes between options
}

export const isNullDate = (date: Date): boolean => {
  if (date instanceof Date) {
    /** 1 day in milliseconds. To account for 01-01-1970, which is technically a valid date.
     * 0 as date will convert to 01-01-1970. So I'm padding it a bit.
     * The day in milliseconds should account for timezone differences.
     */
    return date.valueOf() < 8.64e7;
  }

  throw new TypeError(
    'Argument provided to isNullDate must be a JS Native Date object',
  );
};

export const createTimeIntervalOptions = (
  configs: CreateTimeIntervalOptionsProps,
): Array<string> => {
  const today = new Date();
  let dateArr = [];
  try {
    dateArr = eachMinuteOfInterval({
      start: new Date(
        `${today.toDateString()} ${configs.startHour}:${configs.startMinute ? configs.startMinute : '00'}`,
      ),
      end: new Date(
        `${today.toDateString()} ${configs.endHour}:${configs.endMinute ? configs.endMinute : '01'}`,
      ),
    }).filter(x => getMinutes(x) % configs.interval === 0);
  } catch (error) {
    console.error(error);
  }
  return dateArr.map(date => `${date.getHours()}:${date.getMinutes()}`);
};

export const constructDate = (dateString: string, time: string): Date =>
  new Date(`${dateString} ${time}`);

export const constructDateWithTimezone = (
  dateString: string, // 09/04/2024
  time: string, // 14:30
  timezone: string, // US/Central
): Date => {
  const timeObject = new Date();
  const unformattedHours = time.split(':')[0];
  const unformattedMinutes = time.split(':')[1];
  timeObject.setHours(+unformattedHours);
  timeObject.setMinutes(+unformattedMinutes);
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month =
    dateObject.getMonth() + 1 <= 9
      ? `0${dateObject.getMonth() + 1}`
      : dateObject.getMonth() + 1;
  const day =
    dateObject.getDate() <= 9
      ? `0${dateObject.getDate()}`
      : dateObject.getDate();
  const hours =
    timeObject.getHours() <= 9
      ? `0${timeObject.getHours()}`
      : timeObject.getHours();
  const minutes =
    timeObject.getMinutes() === 0 ? '00' : timeObject.getMinutes();
  return toDate(`${year}-${month}-${day}T${hours}:${minutes}:00`, {
    timeZone: timezone,
  });
};

export const getShortTimeZone = (date: Date, longTimeZone: string): string =>
  formatInTimeZone(date, longTimeZone, 'zzz');

/**
 * @function ISO8601toDateString
 * @summary converts ISO 8061 datetime (YYYY-MM-DDT00:00:00+00:00) to YYYY-MM-DD date string
 * @param {string} ISO8601 datetime as datetime
 * @returns {string} 'YYYY-MM-DD' as datestring
 */
export const ISO8601toDateString = (datetime: string | object): string => {
  // In case date comes back as object (like from calendar selection)
  if (typeof datetime === 'object') {
    datetime = format(new Date(datetime.toString()), 'EEE MMM d yyyy'); // Sun Jun 23 2024
  }
  return datetime.split('T')[0];
};

/**
 * @function dateStringToISO8601
 * @summary converts YYYY-MM-DD to ISO 8601 (YYYY-MM-DDT00:00:00+00:00) format with TZ padding
 * (default time 12AM, to prevent August 1st 1:00AM Eastern from converting to July 31st 10pm Pacific)
 * @param {string} 'YYYY-MM-DD' as dateString
 * @returns {string} ISO 8601 datetime 'YYYY-MM-DDT00:00:00+00:00'
 */
export const dateStringToISO8601 = (dateString: string): string => {
  const baseDate = new Date(dateString);
  const dateWithTZOffset = new Date(
    baseDate.valueOf() + baseDate.getTimezoneOffset() * 60 * 1000,
  );
  return dateWithTZOffset.toISOString();
};

/**
 * @function getDateDifferenceString
 * @summary returns a string of the difference between the current date and the provided date
 * @param {Date} date
 * @returns {string} string of the difference between the current date and the provided date
 * @example getDateDifferenceString(new Date('2021-01-01')) // '2 months ago'
 * @example getDateDifferenceString(new Date('2021-01-01')) // 'in 2 months'
 */
export const getDateDifferenceString = (date?: Date | string): string => {
  if (!date) return '';
  const targetDate = new Date(date);
  const now = new Date();
  return formatDistance(targetDate, now, { addSuffix: true });
};

/**
 * @function timeToHours
 * @summary Converts a time string in the 'HH:mm' format to hours.
 * @param {string} timeString - The time string to convert.
 * @returns {number} The time in hours.
 * @example timeToHours('02:30') // 2.5
 * @example timeToHours('04:45') // 4.75
 */
export function timeToHours(timeString: string): number {
  const time = parse(timeString, 'HH:mm', new Date(1970, 0, 1));
  const hours = getHours(time);
  const minutes = getMinutes(time);
  return hours + minutes / 60;
}

export function convertTo12HourFormat(timeString: string): string {
  const [hourString, minuteString] = timeString.split(':');
  let hour = parseInt(hourString);
  const minute = parseInt(minuteString);
  const period = hour < 12 ? 'am' : 'pm';

  hour = hour % 12 || 12;
  const formattedHour = hour.toString();
  const formattedMinute =
    minute === 0 ? '' : `:${minute.toString().padStart(2, '0')}`;

  return `${formattedHour}${formattedMinute}${period}`;
}

export function convertTo24Hour(time: string, ampm: string): string {
  let hours = parseInt(time.split(':')[0]);
  const minutes = time.split(':')[1];

  if (ampm.toUpperCase() === 'PM' && hours < 12) {
    hours += 12;
  } else if (ampm.toUpperCase() === 'AM' && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, '0')}:${minutes}`;
}

export const dateIsTodayOrFuture = date => !isPast(date);

export const dateIsTomorrowOrLater = date => {
  const today = startOfDay(new Date());
  const targetDate = startOfDay(date);
  return isTomorrow(targetDate) || isAfter(targetDate, today);
};

export const dateIsWithinHoursToday = (date, hours) => {
  const now = new Date();
  if (!isToday(date)) return false;
  const minutesDiff = Math.abs(differenceInMinutes(date, now));
  return minutesDiff <= hours * 60;
};

export const getGenericDateRanges = () => {
  const startOfToday = startOfDay(new Date());
  const yesterday = add(startOfToday, { days: -1 });
  const today = startOfToday;
  const endOfToday = endOfDay(startOfToday);
  const tomorrow = add(startOfToday, { days: 1 });
  const endOfTomorrow = endOfDay(add(startOfToday, { days: 1 }));

  const nextX = (numDays: number) => add(startOfToday, { days: numDays });
  const next3 = nextX(3);
  const next7 = nextX(7);
  const next14 = nextX(14);
  const next30 = nextX(30);

  const lastX = (numDays: number) => nextX(-numDays);
  const last3 = lastX(3);
  const last7 = lastX(7);
  const last14 = lastX(14);
  const last30 = lastX(30);
  const last60 = lastX(60);
  const last90 = lastX(90);
  // WEEK RUNS MON-SUN, INSTEAD OF SUN-SAT. To do regular weeks, get rid of the +1 offset
  const startOfWeek = add(startOfToday, { days: -startOfToday.getDay() + 1 });
  const endOfWeek = add(startOfToday, { days: 7 - startOfToday.getDay() + 1 });
  const startOfPrevWeek = add(startOfToday, {
    days: -startOfToday.getDay() + 1 - 7,
  });
  const endOfPrevWeek = add(startOfToday, {
    days: 7 - startOfToday.getDay() + 1 - 7,
  });
  const startOfMonth = startOfMonthFn(startOfToday);
  const endOfMonth = endOfMonthFn(startOfToday);
  const startOfPrevMonth = add(startOfToday, {
    days: -startOfToday.getDate() + 1,
    months: -1,
  });
  const endOfPrevMonth = add(startOfToday, { days: -startOfToday.getDate() });
  return {
    yesterday,
    today,
    endOfToday,
    tomorrow,
    endOfTomorrow,
    next3,
    next7,
    next14,
    next30,
    last3,
    last7,
    last14,
    last30,
    last60,
    last90,
    startOfWeek,
    endOfWeek,
    startOfPrevWeek,
    endOfPrevWeek,
    startOfMonth,
    endOfMonth,
    startOfPrevMonth,
    endOfPrevMonth,
    nextX,
    lastX,
  };
};
