import { FC, useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  IconProps,
  Popover,
  PopoverBody,
  PopoverBodyProps,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Text,
  TextProps,
  Tooltip,
} from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';
import { hideScrollBarStyles } from '../hideScrollBarStyles';
import { PulseDot } from '../PulseDot';

export interface MultiSelectOptionProps {
  isActive?: boolean;
  toggleIsActive?: () => void;
  label: string;
  value?: string;
  containerProps?: BoxProps;
  hasTooltip?: boolean;
}

export const MultiSelectOption: FC<MultiSelectOptionProps> = ({
  isActive,
  toggleIsActive,
  label,
  containerProps,
  hasTooltip = false,
}) => (
  <Flex
    px={4}
    py={1.5}
    onClick={toggleIsActive}
    alignItems="center"
    cursor="pointer"
    color={isActive ? 'main.green' : 'blackAlpha.700'}
    backgroundColor={isActive && 'green.50'}
    _hover={{ backgroundColor: 'blackAlpha.50' }}
    {...containerProps}
  >
    <Tooltip
      isDisabled={!hasTooltip}
      label={label}
      placement="top"
      openDelay={1000}
    >
      <Text
        flexGrow={1}
        fontWeight="medium"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {label}
      </Text>
    </Tooltip>
    {isActive && <Icon name={ICON_NAMES.check} mr={1} />}
  </Flex>
);

interface MultiSelectControlProps {
  clearSelections?: () => void;
  clearLabel?: string;
  clearButtonProps?: ButtonProps;
  options?: MultiSelectOptionProps[];
  selectedValues?: string[];
  closeOnClear?: boolean;
  popoverProps?: PopoverProps;
  iconProps?: IconProps;
  icon?: ICON_NAMES;
  buttonProps?: ButtonProps;
  showDotOnSelection?: boolean;
  showDotOverride?: boolean;
  showIcon?: boolean;
  showChevron?: boolean;
  label?: string;
  labelProps?: TextProps;
  optionProps?: BoxProps;
  onToggleActiveOption: (value: string) => void;
  contentProps?: PopoverContentProps;
  bodyProps?: PopoverBodyProps;
  containerProps?: FlexProps;
  contentTooltip?: boolean;
}

export const MultiSelectControl: FC<MultiSelectControlProps> = ({
  clearSelections,
  clearLabel = 'Clear Selections',
  clearButtonProps,
  closeOnClear = true,
  options,
  selectedValues,
  popoverProps,
  iconProps,
  icon = ICON_NAMES.baseball,
  buttonProps,
  showDotOnSelection = true,
  showDotOverride = false,
  showIcon = true,
  showChevron = false,
  label = 'Not Sure Yet',
  labelProps,
  optionProps,
  onToggleActiveOption = () => null,
  contentProps,
  bodyProps,
  containerProps,
  contentTooltip = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClearSelections = () => {
    clearSelections();
    if (closeOnClear) {
      setIsOpen(false);
    }
  };

  const numberSelections = selectedValues?.length;
  const hasSelection = numberSelections > 0;

  const toggleActiveOption = (value: string) => {
    onToggleActiveOption(value);
  };

  const contentTooltipWidth =
    contentTooltip &&
    contentProps?.maxWidth &&
    contentProps?.maxWidth?.toString().includes('ch')
      ? contentProps?.maxWidth?.toString().replace('ch', '')
      : null;

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      closeOnBlur={true}
      {...popoverProps}
    >
      <PopoverTrigger>
        <Box
          position="relative"
          {...containerProps}
          className="popover-trigger"
        >
          <Button
            leftIcon={
              showIcon && <Icon name={icon} h={5} w={5} {...iconProps} />
            }
            rightIcon={
              showChevron && (
                <Icon
                  name={ICON_NAMES.chevrondown}
                  h={4}
                  w={4}
                  {...iconProps}
                />
              )
            }
            size="sm"
            variant="transparent"
            sx={{ span: { mr: 1 } }}
            onClick={() => setIsOpen(true)}
            {...buttonProps}
          >
            <Text as="span" {...labelProps}>
              {label}
            </Text>
          </Button>
          {((hasSelection && showDotOnSelection) || showDotOverride) && (
            <PulseDot
              size={2}
              containerProps={{ top: 0, left: 0, position: 'absolute' }}
            />
          )}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          borderColor="main.medium"
          borderWidth="1px"
          borderRadius="md"
          maxHeight={80}
          width={280}
          overflowY="hidden"
          userSelect="none"
          _focus={{ borderColor: 'blackAlpha.200' }}
          shadow="sm"
          {...contentProps}
        >
          <PopoverBody
            p={0}
            display="flex"
            flexDirection="column"
            sx={hideScrollBarStyles}
            _hover={{ overflowY: 'scroll' }}
            _active={{ overflowY: 'scroll' }}
            _focus={{ overflowY: 'scroll' }}
            {...bodyProps}
          >
            {options?.map(option => (
              <MultiSelectOption
                key={option?.value}
                isActive={selectedValues?.includes(option?.value)}
                toggleIsActive={() => toggleActiveOption(option?.value)}
                label={option?.label}
                containerProps={optionProps}
                hasTooltip={
                  !!contentTooltipWidth &&
                  option?.label?.length > Number(contentTooltipWidth)
                }
              />
            ))}
            {!!clearSelections && hasSelection && (
              <Button
                variant="transparent"
                size="sm"
                width="90%"
                alignSelf="center"
                my={2}
                onClick={handleClearSelections}
                {...clearButtonProps}
              >
                {clearLabel}
              </Button>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
