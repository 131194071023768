import { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import {
  Button,
  Flex,
  IconButton,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { format, isValid } from 'date-fns';
import { Icon } from 'icons';
import { isEqual } from 'lodash';
import { OPS_SETTINGS } from 'types/src/routes';
import { contextFilterDefaults } from 'utils/context/opsContext';
import { UserPreferences } from 'utils/helpers/localStorage';
import { usePreferences } from 'utils/hooks/usePreferences';
import {
  FilterOptionsObject,
  FilterOptionsObjectDates,
} from 'utils/types/filters';

const DefaultIndicator: FC = () => (
  <Link href={OPS_SETTINGS} passHref>
    <Text
      px={1.5}
      ml={1}
      borderRadius="md"
      backgroundColor="purple.100"
      color="purple.500"
      cursor="pointer"
    >
      {'Default'}
    </Text>
  </Link>
);

const FILTER_SWAP = {
  timing: {
    hide: dates =>
      Object.keys(dates)?.length > 0 &&
      (isValid(dates?.start) || isValid(dates?.end)),
    swap: 'dates',
    return: dates => ({
      name: 'dates',
      value: 'dates',
      label: `${format(dates.start, 'MM/dd')}${dates.end ? `-${format(dates.end, 'MM/dd')}` : ''}`,
    }),
  },
};

interface PageHeaderFiltersProps {
  // FUTURE: enum this and update refs. Consolidate with PageHeader interface
  filtersContext?:
    | 'shifts'
    | 'workers'
    | 'locations'
    | 'reviews'
    | 'tags'
    | 'campaigns';
  filters?: FilterOptionsObject | FilterOptionsObjectDates;
  filtersToShow?: Array<string>;
  onFiltersClick?: () => void;
  onFilterReset?: (filter: string) => void;
  onFiltersAllReset?: () => void;
  search?: string;
  onSearchUpdate?: (value: string) => void;
}

export const PageHeaderFilters: FC<PageHeaderFiltersProps> = ({
  filtersContext,
  filters,
  onFiltersClick,
  onFilterReset,
  onFiltersAllReset,
  filtersToShow,
  onSearchUpdate,
  search,
}) => {
  const [displaySearch, setDisplaySearch] = useState(false);
  const [
    hasFilterSettingsOverridesActive,
    setHasFilterSettingsOverridesActive,
  ] = useState({
    markets: false,
    status: false,
    timing: false,
    paperwork: false,
  });

  const { preferences } = usePreferences();

  const displayFilters = Object.keys(filters)
    ?.map(key => {
      if (!filtersToShow.includes(key)) return null;
      if (Object.keys(FILTER_SWAP).includes(key)) {
        // if swap contains the filter, check if hide, then return filter display obj
        if (FILTER_SWAP[key].hide(filters[FILTER_SWAP[key].swap])) {
          return FILTER_SWAP[key].return(filters[FILTER_SWAP[key].swap]);
        }
      }
      const name = key;
      const filterObj = filters[key][0];
      let { value } = filterObj || {};
      let { label } = filterObj || {};
      if (filters[key].length > 1) {
        if (key === 'markets') {
          label = filters[key].map(x => x.label.split(',')[0]).join(' | ');
          value = filters[key].length;
        } else {
          label = 'Multi';
        }
      }
      return {
        name,
        value,
        label,
      };
    })
    .filter(filter => !!filter);

  const emptyFilters = displayFilters.filter(filter => !filter.label);
  const nonEmptyFilters = displayFilters.filter(filter => filter.label);

  const filterHasClear = filter => {
    // if it's non-standard based on context default, return true
    if (!filter?.name || !filter?.value) return false;
    // dates are array, which is dates only for now, return true
    if (!(contextFilterDefaults[filtersContext][filter.name] instanceof Object))
      return true;
    return (
      contextFilterDefaults[filtersContext][filter.name][0]?.value !==
      filter.value
    );
  };

  const filtersAreNotDefault = () => {
    return (
      !!onFiltersAllReset &&
      !isEqual(filters, contextFilterDefaults[filtersContext])
    );
  };

  useEffect(() => {
    if (!preferences || !filters) return;
    const activeMarketFiltersValues = filters['markets']?.map(m => m?.value);
    const settingsMarketFilterValues =
      preferences[UserPreferences.DEFAULT_FILTERS]?.markets?.map(
        m => m?.value,
      ) || [];
    setHasFilterSettingsOverridesActive(current => ({
      ...current,
      markets: isEqual(activeMarketFiltersValues, settingsMarketFilterValues),
    }));
  }, [preferences, filters]);

  useEffect(() => {
    if (!preferences || !filters || filtersContext !== 'shifts') return;
    const activeStatusFiltersValues =
      filters['status']?.map(s => s?.value) || [];
    const settingsStatusFilterValues =
      preferences[UserPreferences.DEFAULT_FILTERS]?.status?.map(
        s => s?.value,
      ) || [];
    const activeTimingFiltersValues =
      filters['timing']?.map(t => t?.value) || [];
    const settingsTimingFilterValues =
      preferences[UserPreferences.DEFAULT_FILTERS]?.timing?.map(
        t => t?.value,
      ) || [];
    const activePaperworkFiltersValues =
      filters['paperwork']?.map(p => p?.value) || [];
    const settingsPaperworkFilterValues =
      preferences[UserPreferences.DEFAULT_FILTERS]?.paperwork?.map(
        p => p?.value,
      ) || [];
    setHasFilterSettingsOverridesActive(current => ({
      ...current,
      status: isEqual(activeStatusFiltersValues, settingsStatusFilterValues),
      timing: isEqual(activeTimingFiltersValues, settingsTimingFilterValues),
      paperwork: isEqual(
        activePaperworkFiltersValues,
        settingsPaperworkFilterValues,
      ),
    }));
  }, [preferences, filters, filtersContext]);

  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      mt={{ base: 2, sm: 0 }}
      flexGrow={1}
      flexShrink={0}
    >
      {filtersToShow.includes('search') && !displaySearch && (
        <Button
          leftIcon={<Icon name="search" h={5} w={5} />}
          size="sm"
          variant="transparent"
          onClick={() => setDisplaySearch(true)}
          mr={4}
          data-testid="page-header-filter-search-button"
        >
          {'Search'}
        </Button>
      )}
      {filtersToShow.includes('search') && displaySearch && onSearchUpdate && (
        <>
          <Input
            name="search"
            placeholder="Search"
            data-testid="page-header-filter-search-input"
            value={search.toString()}
            onChange={e => {
              onSearchUpdate(e.target.value);
            }}
            display="inline-flex"
            maxWidth={240}
            mr={2}
          />
          <Button
            size="xs"
            variant="transparent"
            onClick={() => {
              onSearchUpdate('');
            }}
            fontWeight="medium"
            fontSize="xs"
            px={5}
          >
            {'Clear'}
          </Button>
        </>
      )}
      {displayFilters.length === emptyFilters.length && (
        <Button
          leftIcon={<Icon name="filter" h={5} w={5} />}
          size="sm"
          variant="transparent"
          onClick={onFiltersClick}
        >
          {'Filter'}
        </Button>
      )}
      {displayFilters.length !== emptyFilters.length && (
        <IconButton
          icon={<Icon name="filter" h={5} w={5} />}
          aria-label="Open Filters"
          data-testid="open-filters"
          variant="transparent"
          size="sm"
          onClick={onFiltersClick}
          mr={!!onFiltersAllReset && filtersAreNotDefault() ? 0 : 4}
        />
      )}
      {!!onFiltersAllReset && filtersAreNotDefault() && (
        <Button
          size="sm"
          variant="transparent"
          mr={4}
          onClick={onFiltersAllReset}
        >
          {'Reset Filters'}
        </Button>
      )}
      <Flex flexDirection="row" flexWrap="wrap">
        {nonEmptyFilters.map((filter, index) => (
          <Flex
            flexDirection="column"
            onClick={onFiltersClick}
            key={`${filter?.name} - ${filter.label}`}
            mr={index < displayFilters.length - 1 ? 4 : 0}
            mb={2}
          >
            <Flex fontWeight="semi" fontSize="xs" mb={0.5}>
              <Text textTransform="capitalize" fontWeight="semi" fontSize="xs">
                {filter.name}
              </Text>
              {filter?.name === 'markets' && filters['markets'].length > 1 && (
                <Text ml={0.5}>{`(${filter?.value})`}</Text>
              )}
              {hasFilterSettingsOverridesActive.markets &&
                filter?.name === 'markets' && <DefaultIndicator />}
              {filtersContext === 'shifts' && (
                <>
                  {hasFilterSettingsOverridesActive.status &&
                    filter?.name === 'status' && <DefaultIndicator />}
                  {hasFilterSettingsOverridesActive.timing &&
                    filter?.name === 'timing' && <DefaultIndicator />}
                  {hasFilterSettingsOverridesActive.paperwork &&
                    filter?.name === 'paperwork' && <DefaultIndicator />}
                </>
              )}
            </Flex>
            <Tag
              alignSelf="flex-start"
              cursor="pointer"
              py={1.5}
              maxHeight={1.5}
            >
              <TagLabel maxWidth="200px">{filter.label}</TagLabel>
              {filterHasClear(filter) && (
                <TagCloseButton
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onFilterReset && onFilterReset(filter.name);
                  }}
                />
              )}
            </Tag>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
