import { uuid } from './common';
import { document } from './document';
import { review } from './review';
import { shift } from './shift';
import { user } from './user';
import { workerShiftEvent } from './workershiftEvent';

export enum WorkerShiftStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  OVERBOOKED = 'OVERBOOKED',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  INTERESTED = 'INTERESTED',
  PENDING = 'PENDING',
  DROPPED = 'DROPPED',
  // CONFIRMED = 'CONFIRMED', DEPRECATED
  // MISSED = 'MISSED', DEPRECATED
}

export enum WorkerShiftConfirmationStatus {
  HOUR_24 = 'HOUR_24',
  HOUR_2 = 'HOUR_2',
  ARRIVAL = 'ARRIVAL',
}

export enum WorkerShiftCancelClassification {
  ADMIN_PULL = 'ADMIN_PULL',
  ADMIN_CANCEL = 'ADMIN_CANCEL',
  PARTNER_CANCEL = 'PARTNER_CANCEL',
  WORKER_CANCEL = 'WORKER_CANCEL',
  NONE_SPECIFIED = 'NONE_SPECIFIED',
}

export const WorkerShiftCancelClassificationLabel = {
  [WorkerShiftCancelClassification.ADMIN_PULL]: [
    WorkerShiftCancelClassification.ADMIN_PULL,
    'Admin Cancel: Penalty',
  ],
  [WorkerShiftCancelClassification.ADMIN_CANCEL]: [
    WorkerShiftCancelClassification.ADMIN_CANCEL,
    'Admin Cancel: No Penalty',
  ],
  [WorkerShiftCancelClassification.WORKER_CANCEL]: [
    WorkerShiftCancelClassification.WORKER_CANCEL,
    'Worker Cancel: Penalty',
  ],
  [WorkerShiftCancelClassification.PARTNER_CANCEL]: [
    WorkerShiftCancelClassification.PARTNER_CANCEL,
    'Partner Cancel: No Penalty',
  ],
  [WorkerShiftCancelClassification.NONE_SPECIFIED]: [
    WorkerShiftCancelClassification.NONE_SPECIFIED,
    'None Specified',
  ],
};

export type workershift = {
  acceptedAt?: string; // DateTime
  acceptedDisclaimers?: document[];
  breakDurationMinutes?: number;
  cancelClassification?: WorkerShiftCancelClassification;
  cancellationPenalty?: number;
  cancelledBy?: user;
  cancelNote?: string;
  cancellationTime?: string; // DateTime
  checkInTime?: string; // DateTime
  checkOutTime?: string; // DateTime
  createdAt?: string; // DateTime
  confirmationStatus?: string; // JSONString
  hasCompletedCompliancePaperwork?: boolean;
  id?: number;
  lastViewed?: string; // DateTime
  messageCount?: number;
  reviews?: review[];
  shift?: shift;
  status?: WorkerShiftStatus;
  updatedAt?: string; // DateTime
  uuid?: uuid;
  viewCount?: number;
  worker?: user;
  workershiftEvents?: workerShiftEvent[];
};
