import { FC } from 'react';
import { Flex, FlexProps, Switch, Tooltip } from '@chakra-ui/react';
import {
  LocationPermissionDescriptions,
  LocationPermissionIcons,
  LocationPermissionLabels,
} from 'types';
import { TextAndIcon } from '../TextAndIcon';
import { PermissionOptionState } from './PermissionCard';

interface PermissionToggleOptionProps {
  state?: PermissionOptionState;
  permission: string; // LocationPermission
  handleUpdateState: (permission: string) => void;
  containerProps?: FlexProps;
  isDisabled?: boolean;
}

export const PermissionToggleOption: FC<PermissionToggleOptionProps> = ({
  state = PermissionOptionState.NEGATIVE,
  permission,
  handleUpdateState,
  containerProps,
  isDisabled = true,
}) => {
  const onUpdate = () => handleUpdateState(permission);

  return (
    <Flex
      className="permission-option"
      flexDirection="row"
      alignItems="center"
      p={2}
      pr={4}
      {...containerProps}
    >
      <Flex flexGrow={1}>
        <Tooltip
          label={LocationPermissionDescriptions[permission]}
          openDelay={1000} // ms
          gutter={0}
        >
          <Flex>
            <TextAndIcon
              text={LocationPermissionLabels[permission]}
              textProps={{ variant: 'label-md' }}
              icon={LocationPermissionIcons[permission]}
              iconProps={{ h: 5, w: 5, ml: 1 }}
              containerProps={{ flexGrow: 1, gap: 2 }}
            />
          </Flex>
        </Tooltip>
      </Flex>
      <Switch
        isChecked={state === PermissionOptionState.POSITIVE}
        onChange={onUpdate}
        colorScheme="green"
      />
    </Flex>
  );
};
