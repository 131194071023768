import { extendTheme } from '@chakra-ui/react';
import { Calendar } from './components/Calendar';
import { Button } from './config/button.config';
import { colors } from './config/colors.config';
import { Divider } from './config/divider.config';
import { Heading } from './config/heading.config';
import { Input } from './config/input.config';
import { Select } from './config/select.config';
import { Text } from './config/text.config';
import { typography } from './config/typography.config';

const theme = extendTheme({
  ...typography,
  colors,
  components: {
    Button,
    Text,
    Input,
    Select,
    Popover: {
      variants: {
        responsive: {
          popper: {
            maxWidth: 'unset',
            width: 'unset',
          },
        },
      },
    },
    Skeleton: {
      defaultProps: {
        startColor: 'blackAlpha.100',
        endColor: 'blackAlpha.300',
      },
    },
    Heading,
    Divider,
    ...Calendar,
  },
});

export default theme;
