import { communicationBatch } from './communicationBatch';
import { messageHistory } from './messageHistory';
import { shift } from './shift';
import { user } from './user';

export enum CommunicationStatus {
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  RESOLVED = 'RESOLVED',
}

export enum CommunicationMethod {
  TEST = 'TEST',
  TEXT = 'TEXT',
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SLACK = 'SLACK',
}

export type communication = {
  attributionId?: string; // FUTURE: Deprecate for batch.uuid
  batch?: communicationBatch;
  content?: string;
  createdAt?: string; // DateTime
  event?: messageHistory;
  id?: string;
  meta?: string; // JSONField: communicationMeta
  method?: CommunicationMethod;
  status?: CommunicationStatus;
  to?: user;
  updatedAt?: string; // DateTime
  shift?: shift;
};

export type communicationMeta = {
  phone?: string;
  media_urls?: string[];
  twilio_sid?: string;
  front_message_id?: string;
  front_conversation_id?: string;
  sender_id?: string;
  title?: string;
  device_arn?: string;
  data?: unknown; // Push link data
};
