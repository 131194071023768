import { location, LocationStatus } from 'types';
import { getShortTimeZone } from '../dates';

export interface locationNameOptions {
  displayRetailerName?: boolean;
  preferBillingName?: boolean;
}

const defaultLocationNameOptions: locationNameOptions = {
  displayRetailerName: false,
  preferBillingName: false,
};

export const getLocationNameForDisplay = (
  location: location,
  options?: locationNameOptions,
): string => {
  const {
    displayRetailerName = defaultLocationNameOptions.displayRetailerName,
    preferBillingName = defaultLocationNameOptions.preferBillingName,
  } = options || defaultLocationNameOptions;
  const hasBillingName = !!location?.billingName;
  const locationName =
    preferBillingName && hasBillingName
      ? location?.billingName
      : location?.name;

  if (!location?.retailer) return locationName;
  if (displayRetailerName)
    return `${location?.retailer.name} - ${locationName}`;
  return locationName;
};

export const getSortedLocations = (
  locations: location[],
  sortByKey: 'name' | 'billingName',
  sortRetailerName = true,
) => {
  return locations?.sort((a, b) => {
    if (sortRetailerName) {
      const retailerA = a?.retailer?.name || '';
      const retailerB = b?.retailer?.name || '';

      if (retailerA !== retailerB) {
        return retailerA.localeCompare(retailerB);
      }
    }

    const aValue =
      sortByKey === 'billingName' && !a[sortByKey] ? a.name : a[sortByKey];
    const bValue =
      sortByKey === 'billingName' && !b[sortByKey] ? b.name : b[sortByKey];
    return aValue.localeCompare(bValue);
  });
};

export const getLocationComplianceInformation = (
  location: location,
  minShiftLength: number,
  maxShiftLength: number,
) => {
  const hasCompliance = !!location?.compliance;
  const shiftMaxLength =
    (location?.compliance?.shiftMaxLength ?? maxShiftLength) / 60;
  const shiftMinLength =
    (location?.compliance?.shiftMinLength ?? minShiftLength) / 60;
  const breakRules = location?.compliance?.breakRules[0];
  const minShiftHoursToRequireBreak =
    breakRules?.shiftMinutesToRequireBreak / 60 || 0;
  const isBreakPaid = breakRules?.paid;
  const breakLength = breakRules?.breakLength;
  return {
    hasCompliance,
    shiftMaxLength,
    shiftMinLength,
    breakRules,
    minShiftHoursToRequireBreak,
    isBreakPaid,
    breakLength,
  };
};

export const getLocationTimezones = location => {
  const longTimeZone = location?.timezone ? location.timezone : 'US/Central';
  const timezones = {
    shortTimeZone: getShortTimeZone(new Date(), longTimeZone),
    longTimeZone,
  };
  return timezones;
};

export const canRetailerUserBookForLocation = (
  status: LocationStatus,
): boolean => [LocationStatus.ACTIVE].includes(status);

export const canOpsUserBookForLocation = (status: LocationStatus): boolean => {
  return true;
  // FUTURE: enable when ready
  // [LocationStatus.ACTIVE, LocationStatus.ONBOARDED].includes(status);
};

export const getFullAddress = (location?: location): string => {
  const { address } = location || {};
  if (!address || !Object.keys(address).length) return '';
  return `${address?.address1}, ${
    address?.address2 ? address?.address2 + ', ' : ''
  } ${address?.city}, ${address?.state} ${address?.zip}`;
};
