import { create } from 'zustand';
import { QueryParamDrawer, QueryParamModal } from 'types/src/queryParams';

export { QueryParamModal };

interface ViewStore {
  modalOpen?: QueryParamModal;
  openModal: (QueryParamModal) => void;
  closeModal: () => void;
  drawerOpen?: QueryParamDrawer;
  openDrawer: (QueryParamDrawer) => void;
  closeDrawer: () => void;
}

const viewStore = (set): ViewStore => ({
  modalOpen: null,
  openModal: (modal: QueryParamModal) => {
    set({ modalOpen: modal }, false, 'openModal');
  },
  closeModal: () => set({ modalOpen: null }, false, 'closeModal'),
  drawerOpen: null,
  openDrawer: (drawer: QueryParamDrawer) => {
    set({ drawerOpen: drawer }, false, 'openDrawer');
  },
  closeDrawer: () => set({ drawerOpen: null }, false, 'closeDrawer'),
});

export const useViewStore = create(viewStore);
