import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { StarFill, StarHalf, Star as StarIcon } from 'icons';

export type StarRow = {
  rating: number;
  isDisabled?: boolean;
};

export type StarType = {
  color?: string;
  type: string;
};

export const STAR_BY_TYPE = {
  full: StarFill,
  half: StarHalf,
  empty: StarIcon,
};

export const Star: FC<StarType> = ({ color, type, ...rest }) => {
  const StarComponent = STAR_BY_TYPE[type] || STAR_BY_TYPE['empty'];
  return (
    <StarComponent
      color={color ? color : 'yellow.400'}
      mr="1px"
      height={5}
      width={5}
      {...rest}
    />
  );
};

export const StarRow: FC<StarRow> = ({ rating, isDisabled = false }) => {
  const ratingMap = [
    rating >= 1 ? 'full' : rating >= 0.5 ? 'half' : 'empty',
    rating >= 2 ? 'full' : rating >= 1.5 ? 'half' : 'empty',
    rating >= 3 ? 'full' : rating >= 2.5 ? 'half' : 'empty',
    rating >= 4 ? 'full' : rating >= 3.5 ? 'half' : 'empty',
    rating >= 5 ? 'full' : rating >= 4.5 ? 'half' : 'empty',
  ];
  return (
    <Box>
      {ratingMap.map((type, index) => (
        <Star
          key={index}
          type={isDisabled ? 'full' : type}
          color={isDisabled && 'navy.100'}
        />
      ))}
    </Box>
  );
};
