import { useContext } from 'react';
import { requireProperties } from 'logging';
import { LoggingContext } from 'utils/context/loggingContext';

export const useLogging = () => {
  const context = useContext(LoggingContext);
  if (context === null) {
    throw new Error('useLogging must be used within a LoggingProvider');
  }

  return (obj, properties) => requireProperties(obj, properties, context.warn);
};
