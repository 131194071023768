import { address } from './address';
import { billingInvoiceMixin, PaymentContractTypeOptions } from './billing';
import { uuid } from './common';
import { apiCompliance, compliance } from './compliance';
import { customer } from './customer';
import { locationBudget } from './locationBudgets';
import { graphMarket } from './market';
import { noteType } from './note';
import { organization } from './organization';
import { LocationPermission } from './permissions';
import { playbook } from './playbook';
import { LocationCommunicationPreference } from './preferences';
import { graphRetailer } from './retailer';
import { shift } from './shift';
import { tag } from './tag';
import { terms } from './terms';
import { extendedUserDetails, user } from './user';

export const LocationSortOptions = {
  name: 'Name',
  budget_utilization: 'Budget Progress',
  budget_amount: 'Budget Amount',
} as const;

export const InternalLocationSortOptions = {
  ...LocationSortOptions,
  retailer__name: 'Retailer',
};

export enum LocationRelations {
  FAVORITE = 'FAVORITE',
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
}

export const LocationRelationLabels = {
  [LocationRelations.FAVORITE]: 'Favorite',
  [LocationRelations.APPROVED]: 'Approved',
  [LocationRelations.BLOCKED]: 'Blocked',
} as const;

export enum UserInitiatedLocationRelations {
  USER_FAVORITE = 'USER_FAVORITE',
  USER_APPROVED = 'USER_APPROVED',
  USER_BLOCKED = 'USER_BLOCKED',
}

export enum LocationStatus {
  ONBOARDED = 'ONBOARDED', // Onboarded, waiting for market to open, not able to post
  ACTIVE = 'ACTIVE', // Fully active: able to post, market open
  INACTIVE = 'INACTIVE', // Inactive, not able to post
  CLOSED = 'CLOSED', // Closed, no longer in business, not able to post
  CHURNED = 'CHURNED', // Churned, no active contract, not able to post
}

export const LocationStatusLabels = {
  [LocationStatus.ONBOARDED]: "Onboarded (market not open, can't book)",
  [LocationStatus.ACTIVE]: 'Active',
  [LocationStatus.INACTIVE]: 'Inactive (can book)',
  [LocationStatus.CLOSED]: "Closed (no longer in business, can't book)",
  [LocationStatus.CHURNED]: "Churned (no active contract, can't book)",
};

export enum LocationParking {
  FREE = 'FREE',
  PAID = 'PAID',
  VALIDATED = 'VALIDATED',
  REIMBURSED = 'REIMBURSED',
  NONE = 'NONE',
  UNKNOWN = 'UNKNOWN',
}

export const LocationParkingLabels = {
  [LocationParking.FREE]: 'Free',
  [LocationParking.PAID]: 'Paid',
  [LocationParking.VALIDATED]: 'Validated',
  [LocationParking.UNKNOWN]: 'Unknown',
  [LocationParking.NONE]: 'None',
};

export enum LocationType {
  STORE = 'STORE',
  WAITLIST = 'WAITLIST',
}

export enum LocationBillingDayOfWeek {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export enum LocationContacts {
  MAIN = 'contactsCommunications',
  BILLING = 'contactsBilling',
  CORPORATE = 'contactsCorporate',
  DISTRICT_MANAGER = 'contactsDistrictManager',
  STORE_MANAGER = 'contactsStoreManager',
  ASSISTANT_STORE_MANAGER = 'contactsAssistantStoreManager',
}

export type graphRetailerLocationLight = {
  id?: number;
  name?: string;
  type?: LocationType;
  uuid?: string;
  market?: {
    name?: string;
  };
  retailerName?: string;
  retailerId?: number;
  timezone?: string;
  retailerlogoImage?: string;
  compliance?: compliance;
};

export type LocationReflexer = user & {
  eritId?: string;
  totalShiftsAtLocation: string;
  lastShiftAtLocation?: {
    start: string;
    pendingReviews: {
      id: string;
      uuid: string;
    }[];
  };
};

export type graphRetailerLocationWorkers = Pick<
  location,
  'favorites' | 'approved' | 'blocked'
>;

export type graphRetailerLocationContact = Pick<
  extendedUserDetails,
  'uuid' | 'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'profileImg'
>;

export type location = billingInvoiceMixin & {
  id?: number;
  uuid?: string;
  name?: string;
  phoneNumber?: string;
  storeEmail?: string;
  isActive?: boolean;
  status?: LocationStatus;
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  address?: address;
  customer?: customer;
  retailer?: graphRetailer;
  terms?: terms;
  notes?: noteType[];
  defaultPlaybook?: playbook;
  approvedWorkers?: Array<user>; // FUTURE: name this 'store managers' or similar
  favorites?: Array<Record<string, Partial<extendedUserDetails>>>;
  approved?: Array<Record<string, Partial<extendedUserDetails>>>;
  blocked?: Array<Record<string, Partial<extendedUserDetails>>>;
  type?: LocationType;
  paymentType?: PaymentContractTypeOptions;
  mapImg?: string;
  mapHref?: string;
  market?: graphMarket;
  payRate?: number;
  shifts?: Array<shift>;
  allShifts?: Array<shift>;
  timezone?: string;
  totalHoursThisMonth?: number;
  totalHoursUpcomingMonth?: number;
  totalHoursAllTime?: number;
  latestShiftDate?: Date;
  retailerAverageRating?: number;
  outstandingRetailerReviews?: number;
  [LocationContacts.BILLING]?: Array<Partial<extendedUserDetails>>;
  [LocationContacts.MAIN]?: Array<Partial<extendedUserDetails>>;
  [LocationContacts.CORPORATE]?: Array<Partial<extendedUserDetails>>;
  [LocationContacts.DISTRICT_MANAGER]?: Array<Partial<extendedUserDetails>>;
  [LocationContacts.STORE_MANAGER]?: Array<Partial<extendedUserDetails>>;
  [LocationContacts.ASSISTANT_STORE_MANAGER]?: Array<
    Partial<extendedUserDetails>
  >;
  workers?: LocationReflexer[];
  tags?: Array<tag>;
  compliance?: compliance;
  approvedUsers?: {
    permissions?: LocationPermission[];
    user?: user;
  }[];
  organization?: organization;
  locationBudgets?: locationBudget[];
  currentBudget?: locationBudget;
  myPermissions?: LocationPermission[]; // for requesting user
  specialRequestsAvailable?: number;
  useV2FlexDetails?: boolean;
  parking?: LocationParking;
  communicationUsers?: communicationUser[];
  billingName?: string;
};

export type graphAddress = {
  address: string;
  id: number;
  name?: string;
  mapHref?: string;
  mapImg?: string;
};

// DEPRECATED
export type apiRetailerLocation = {
  address: string;
  compliance?: apiCompliance;
  id: number;
  uuid?: uuid;
  name?: string;
  map_href?: string;
  map_img?: string;
  retailer_sheet_href?: string;
  retailer_name?: string;
  retailer_id?: string;
  retailer_brand_img?: string;
  created_at?: string; // DateTime
  timezone: string;
  use_v2_flex_details?: boolean;
};

export type communicationUser = {
  types: LocationCommunicationPreference[];
  user: user;
};
